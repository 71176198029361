import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";
import { ProjectType } from "./type";


const convertToFormData = (obj: any) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item) => formData.append(key, item));
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const projectSlice = createApi({
  reducerPath: "project",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    createProject: builder.mutation({
      query: (newTask) => {
        const formData = convertToFormData(newTask);
        return {
          url: "/organization/api/v1/projects/",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateProject: builder.mutation({
      query: ({ id, newProject }: { id: number; newProject: any }) => {
        if (newProject.thumbnail === null) {
          delete newProject.thumbnail;
        }

        const formData = convertToFormData(newProject);
        return {
          url: `/organization/api/v1/projects/${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getAllProjects: builder.query<ProjectType[], void>({
      query: () => "/organization/api/v1/projects/",
    }),
    getAllProjectsByUserRole: builder.query<ProjectType[], void>({
      query: () => "/organization/api/v1/projects_by_user_role/",
    }),
    getprojectById: builder.query<ProjectType, string | undefined>({
      query: (id) => `/organization/api/v1/projects/${id}/`,
    }),
    getProjectByEmployeeId: builder.query<ProjectType[], string | undefined>({
      query: (employee_id) => `/organization/api/v1/projects/?employee_id=${employee_id}`,
    }),
    // getAllPriority: builder.query<PriorityType[], void>({
    //   query: () => "/task/api/v1/task_priority/",
    // }),
    getProjectAttachmentById: builder.query<any[], string | undefined>({
      query: (id) => `/organization/api/v1/projects_attachment/${id}/`,
    }),

    getAllProjectAttachments: builder.query<any[], string | undefined>({
      query: (id) => `/organization/api/v1/projects_attachment/?project=${id}`,
    }),

    createProjectAttachment: builder.mutation({
      query: (newAttachment) => {
        const formData = convertToFormData(newAttachment);
        return {
          url: "/organization/api/v1/projects_attachment/",
          method: "POST",
          body: formData,
        };
      },
    }),

    deleteProjectAttachmentById: builder.mutation({
      query: (id: string) => ({
        url: `/organization/api/v1/projects_attachment/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetAllProjectsQuery,
  useGetAllProjectsByUserRoleQuery,
  useGetprojectByIdQuery,
  useGetProjectAttachmentByIdQuery,
  useGetAllProjectAttachmentsQuery,
  useGetProjectByEmployeeIdQuery,
  useCreateProjectAttachmentMutation,
  useDeleteProjectAttachmentByIdMutation,
} = projectSlice;
