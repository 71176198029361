import React, { useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import { format, addDays } from "date-fns";
import LoadingOverlay from "react-loading-overlay-ts";
import { useParams, useNavigate } from "react-router-dom";
import {
  useCreateToolboxMeetingMutation,
  useUpdateToolboxMeetingMutation,
  useGetToolboxMeetingByIdQuery,
} from "slices/safetyApp/safetyappSlice"; // Adjust imports based on your slice structure
import { useGetProjectByEmployeeIdQuery } from "slices/projects/projectSlice";
import { ServiceContext } from "context/ServiceContextProvider";

interface Step1ToolBoxDetailsProps {
  nextStep: () => void;
  geoPermission: boolean;
}

interface FormValues {
  contractor: string;
  project: number | "";
  start_date_of_work: string;
  start_time_of_work: string;
  end_date_of_work: string;
  end_time_of_work: string;
  work_description: string;
}

const Step1ToolBoxDetails: React.FC<Step1ToolBoxDetailsProps> = ({
  nextStep,
  geoPermission,
}) => {
  const { toolBoxId } = useParams<{ toolBoxId: string }>();
  const navigate = useNavigate();

  const { getEmployeeID } = useContext(ServiceContext);

  const { data: projectData, refetch: refetchProject } = useGetProjectByEmployeeIdQuery(
    getEmployeeID
  );
  const { data: toolboxData, refetch: refetchToolbox, isFetching: isToolBoxFetching, isLoading: isToolBoxLoading } =
    useGetToolboxMeetingByIdQuery(toolBoxId);

  const [createToolboxMeeting, { isLoading: createLoading }] =
    useCreateToolboxMeetingMutation();
  const [updateToolboxMeeting, { isLoading: updateLoading }] =
    useUpdateToolboxMeetingMutation();

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  useEffect(() => {
    // Ensure data is fetched and not currently loading
    if (!isToolBoxLoading && toolboxData) {
      formik.setValues({
        contractor: toolboxData?.contractor || "",
        project: toolboxData?.project || "",
        start_date_of_work: toolboxData?.start_date_of_work || getCurrentDate(),
        start_time_of_work: toolboxData?.start_time_of_work || "",
        end_date_of_work: toolboxData?.end_date_of_work || "",
        end_time_of_work: toolboxData?.end_time_of_work || "",
        work_description: toolboxData?.work_description || "",
      });
    }
    refetchProject();
  }, [isToolBoxLoading, isToolBoxFetching, toolboxData]);

  const formik = useFormik<FormValues>({
    initialValues: {
      contractor: toolboxData?.contractor || "",
      project: toolboxData?.project || "",
      start_date_of_work: toolboxData?.start_date_of_work || getCurrentDate(),
      start_time_of_work: toolboxData?.start_time_of_work || "",
      end_date_of_work: toolboxData?.end_date_of_work || "",
      end_time_of_work: toolboxData?.end_time_of_work || "",
      work_description: toolboxData?.work_description || "",
    },
    validationSchema: Yup.object({
      contractor: Yup.string().required("Contractor is required"),
      project: Yup.number().required("Project is required").nullable(),
      start_date_of_work: Yup.string()
        .required('Start date is required')
        .test(
          'is-not-in-past',
          'Start date cannot be in the past',
          function (value) {

            console.log('value', value)
            
            if (!value) return true; // Skip validation if no date is provided
            const inputDate = moment(value, 'YYYY-MM-DD').startOf('day'); // Only date part
            const today = moment().startOf('day');
            return inputDate.isSameOrAfter(today); // Compare only dates
          }
        ),
      start_time_of_work: Yup.string().required("Start time is required"),
      end_date_of_work: Yup.date()
      .required("End date is required")
      .test(
        'is-same-or-next-day',
        'End date must be the same or the next day as the start date',
        function (value) {
          const { start_date_of_work } = this.parent; // Access other fields
          if (!start_date_of_work || !value) return true; // Skip if either date is empty
          
          const startDate = moment(start_date_of_work, 'YYYY-MM-DD').startOf('day');
          const endDate = moment(value, 'YYYY-MM-DD').startOf('day');
          
          // Check if the endDate is same or next day compared to startDate
          return endDate.isSame(startDate) || endDate.isSame(startDate.add(1, 'day'));
        }
      ),
      end_time_of_work: Yup.string()
        .required("End time is required")
        .test(
          'is-valid-time',
          "End time must be at least 1 hour after start time",
          function (value) {
            const { start_date_of_work, start_time_of_work, end_date_of_work } = this.parent;

            // Return true if any required field is missing (skipping validation)
            if (!start_date_of_work || !start_time_of_work || !end_date_of_work || !value) {
              return true;
            }

            // Parse start and end date-time strings
            const formattedStartDate = moment(new Date(start_date_of_work)).format('DD/MM/YYYY');
            const formattedStartTime = start_time_of_work.slice(0, 5);
            const startDateTime = moment(
              `${formattedStartDate} ${formattedStartTime}`,
              'DD/MM/YYYY HH:mm',
              true
            );

            const formattedEndDate = moment(new Date(end_date_of_work)).format('DD/MM/YYYY');
            const formattedEndTime = value.slice(0, 5);
            const endDateTime = moment(
              `${formattedEndDate} ${formattedEndTime}`,
              'DD/MM/YYYY HH:mm',
              true
            );

            // Ensure dates are valid
            if (!startDateTime.isValid() || !endDateTime.isValid()) {
              console.error('Invalid date inputs:', { startDateTime, endDateTime });
              return false;
            }

            // Compare end time: ensure it's at least 1 hour after start time
            return endDateTime.isSameOrAfter(startDateTime.clone().add(1, 'hour'));
          }
        ),
      work_description: Yup.string().required("Work description is required"),
    }),
    onSubmit: async (values) => {
      try {
        const payload = { ...values, project: Number(values.project) };

        let response;
        if (toolBoxId) {
          response = await updateToolboxMeeting({
            toolbox_id: toolBoxId,
            updatedData: payload,
          }).unwrap();
        } else {
          response = await createToolboxMeeting(payload).unwrap();
          navigate(`/toolbox-meeting-wizard/${response.toolbox_id}`);
        }

        formik.resetForm();
        nextStep();
      } catch (error) {
        console.error("Failed to submit the form", error);
      }
    },
    validateOnChange: false, // Disable automatic validation on change
    validateOnBlur: true, // Disable automatic validation on blur
  });

  const handleNext = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        formik.handleSubmit();
      } else {
        console.log("Form has validation errors", errors);
      }
    });
  };

  console.log('formik', formik)

  return (
    <Form onSubmit={formik.handleSubmit}>
      <LoadingOverlay active={createLoading || updateLoading} spinner text="Submitting...">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="contractor">
                Contractor <span className="text-danger">*</span>
              </Label>
              <Input
                id="contractor"
                name="contractor"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.contractor}
                invalid={!!formik.errors.contractor}
              />
              {formik.errors.contractor && (
                <div className="text-danger">{formik.errors.contractor}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="project">
                Project <span className="text-danger">*</span>
              </Label>
              <Input
                id="project"
                name="project"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.project}
                invalid={!!formik.errors.project}
              >
                <option value="">Select Project</option>
                {projectData?.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.project_name}
                  </option>
                ))}
              </Input>
              {formik.errors.project && (
                <div className="text-danger">{formik.errors.project}</div>
              )}
            </FormGroup>
          </Col>

          {/* Start and End Date/Time Fields */}
          <Col md={6}>
            <FormGroup>
              <Label for="start_date_of_work">
                Start Date <span className="text-danger">*</span>
              </Label>
              <Flatpickr
                id="start_date_of_work"
                name="start_date_of_work"
                className={`form-control ${formik.touched.start_date_of_work && formik.errors.start_date_of_work ? 'is-invalid' : ''}`}
                options={{
                  minDate: "today",
                  maxDate: "today"
                }}
                value={formik.values.start_date_of_work}
                onChange={(date: Date[]) => {
                  formik.setFieldValue(
                    "start_date_of_work",
                    format(date[0], "yyyy-MM-dd")
                  );
                }}
              />
              {formik.touched.start_date_of_work && formik.errors.start_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_date_of_work}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_time_of_work">
                Start Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_time_of_work"
                name="start_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.start_time_of_work}
                invalid={!!formik.errors.start_time_of_work}
              />
              {formik.errors.start_time_of_work && (
                <div className="text-danger">{formik.errors.start_time_of_work}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_date_of_work">
                End Date <span className="text-danger">*</span>
              </Label>
              <Flatpickr
                id="end_date_of_work"
                name="end_date_of_work"
                className={`form-control ${formik.touched.end_date_of_work && formik.errors.end_date_of_work ? 'is-invalid' : ''}`}
                options={{
                  minDate: "today",
                  maxDate: addDays(new Date(), 1),
                }}
                value={formik.values.end_date_of_work}
                onChange={(date: Date[]) => {
                  formik.setFieldValue(
                    "end_date_of_work",
                    format(date[0], "yyyy-MM-dd")
                  );
                }}
              />
              {formik.touched.end_date_of_work || formik.errors.end_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_date_of_work}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_time_of_work">
                End Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_time_of_work"
                name="end_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.end_time_of_work}
                invalid={
                  formik.touched.end_time_of_work &&
                  !!formik.errors.end_time_of_work
                }
              />
              {formik.touched.end_time_of_work ||
                formik.errors.end_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label for="work_description">
                Work Description <span className="text-danger">*</span>
              </Label>
              <Input
                id="work_description"
                name="work_description"
                type="textarea"
                onChange={formik.handleChange}
                value={formik.values.work_description}
                invalid={!!formik.errors.work_description}
              />
              {formik.errors.work_description && (
                <div className="text-danger">{formik.errors.work_description}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={12}>
            <Button
              type="button"
              color="primary"
              onClick={handleNext}
              disabled={!geoPermission}
              className="float-end"
            >
              {createLoading || updateLoading ? <Spinner size="sm" /> : "Next"}
            </Button>
          </Col>
        </Row>
      </LoadingOverlay>
    </Form>
  );
};

export default Step1ToolBoxDetails;
