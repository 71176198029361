import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import classnames from "classnames";
import { useLocation } from 'react-router-dom';
import OverviewTab from "./OverviewTab";
// import DocumentsTab from "./DocumentsTab";
// import ActivitiesTab from "Components/Common/ActivitesTab";
import { useGetAuditLogsQuery } from "slices/employee/employeeSlice";
import { getTextColor, STATUS_COLORS } from "helpers/common_helper";
import { Tooltip } from "react-tooltip";
import { ServiceContext } from "context/ServiceContextProvider";
import { useGenerateHomeworkPermitPDFMutation } from "slices/safetyApp/safetyappSlice";
import { useNavigate } from "react-router-dom";
// import SafetyAppOverviewTab from "pages/SafetyApp/WorkFlowApprovels/WorkFlowApprovelsOverview/OverviewTab";
// import { useGetSafetyAppApprovalByPermitIdQuery } from "slices/workflow/WorkFlowSlice";
import { BadgeStatus } from "helpers/badgeStatus";
import { IsValidPermissions } from "Routes/AuthProtected";

interface TaskProps {
  toolboxMeetingByIdData: any;
  toggle: any;
}

const Summary: React.FC<TaskProps> = ({
  toolboxMeetingByIdData,
  toggle,
}) => {
  const context = useContext(ServiceContext);
  const { selectedRoleName, getEmployeeUUID } = context;
  const { data: auditLogs, isLoading: auditLogsLoading } = useGetAuditLogsQuery(
    { model_name: "toolbox", object_id: toolboxMeetingByIdData?.id }
  );
  const location = useLocation();
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [confirmRevokeModal, setConfirmRevokeModal] = useState(false);
  const toggleConfirmCloseModal = () => setConfirmCloseModal(!confirmCloseModal);
  const toggleConfirmRevokeModal = () => setConfirmRevokeModal(!confirmRevokeModal);
  const [approvalData, setApprovalData] = useState<any>([]);
  // const {
  //   data: workFlowData,
  //   isLoading: projectLoading,
  //   refetch: refetchProject,
  // } = useGetSafetyAppApprovalByPermitIdQuery(getHomeworkPermitById?.hw_permit_no);

  // useEffect(() => {
  //   if (getHomeworkPermitById?.hw_permit_no) {
  //     refetchProject();
  //   }
  // }, [getHomeworkPermitById?.hw_permit_no, refetchProject]);

  // useEffect(() => {
  //   if (workFlowData) {
  //     setApprovalData(workFlowData);
  //   }
  // }, [workFlowData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const approval = queryParams.get('approval');
    if (approval === 'true') {
      toggleTab("2");
    }
  }, [location]);


  const [generatePDF, { isLoading: isLoadingGeneratePDF }] = useGenerateHomeworkPermitPDFMutation();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const allowClosePermit = (workflowApproval: any) => {
    if (!workflowApproval?.resource_approval_request) {
      return false;
    }

    let currentUser: boolean = false;
    let roleList: string[] = [];

    workflowApproval.resource_approval_request.forEach((approval: any) => {
      if (approval.is_active) {
        // Check condition: approval_status === 'Submitted' && approved_by_uuid === getEmployeeUUID
        const submittedApprovals = approval.resource_approval.filter(
          (ra: any) => ra.approval_status === 'Submitted' && ra.approved_by_uuid === getEmployeeUUID
        );

        if (submittedApprovals.length > 0) {
          // roleList = submittedApprovals.flatMap((ra: any) => ra.role_name);
          // return; // If the condition is met, exit early
          currentUser = true;
        }

        // Check condition: is_allow_close and role_name inclusion
        const closeApprovals = approval.resource_approval.filter((ra: any) => ra.is_allow_close);
        closeApprovals.forEach((ra: any) => {
          if (ra.role_name.includes(selectedRoleName)) {
            roleList.push(...ra.role_name);
          }
        });
      }
    });

    if (currentUser) {
      return true;
    }

    // Remove duplicates
    const uniqueRoles = [...new Set(roleList)];

    // Return true if roles are found or false if not
    return uniqueRoles.length > 0;
  };

  const allowRevokePermit = (workflowApproval: any) => {
    if (!workflowApproval?.resource_approval_request) {
      return false;
    }

    let currentUser: boolean = false;
    let roleList: string[] = [];

    workflowApproval.resource_approval_request.forEach((approval: any) => {
      if (approval.is_active) {
        // Check condition: approval_status === 'Submitted' && approved_by_uuid === getEmployeeUUID
        const submittedApprovals = approval.resource_approval.filter(
          (ra: any) => ra.approval_status === 'Submitted' && ra.approved_by_uuid === getEmployeeUUID
        );

        if (submittedApprovals.length > 0) {
          // roleList = submittedApprovals.flatMap((ra: any) => ra.role_name);
          // return; // If the condition is met, exit early
          currentUser = true;
        }

        // Check condition: is_allow_close and role_name inclusion
        const closeApprovals = approval.resource_approval.filter((ra: any) => ra.is_allow_revoke);
        closeApprovals.forEach((ra: any) => {
          if (ra.role_name.includes(selectedRoleName)) {
            roleList.push(...ra.role_name);
          }
        });
      }
    });

    // if (currentUser) {
    //   return true;
    // }

    // Remove duplicates
    const uniqueRoles = [...new Set(roleList)];

    // Return true if roles are found or false if not
    return uniqueRoles.length > 0;
  };

  const Status = (props: any) => {
    return (
      <React.Fragment>
        <span
          className="badge text-uppercase"
          style={{
            backgroundColor: props.color,
            color: getTextColor(props.color),
          }}
        >
          {props.value}
        </span>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".edit-task-class"
        place="bottom"
        content="Update Permit"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".close-report-class"
        place="bottom"
        content="Close Report"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".upadte-task-status-class"
        place="bottom"
        content="Update Task Status"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".export-task-status-class"
        place="bottom"
        content="Export Permit"
      />

      <div className="page-content pt-3">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4">
                <div>
                  <CardBody className="pb-0 px-4">
                    <Row className="mb-3">
                      <div className="col-md">
                        <Row className="align-items-center g-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h4 className="fw-bold">
                                {" "}
                                ToolBox Meeting Information
                              </h4>
                            </div>
                          </div>
                          <hr />
                          <div className="col-md">
                            <div className="d-flex justify-content-between">
                              <div>
                                <h4 className="fw-bold">
                                  ToobBox ID :{" "}
                                  <span className="text-muted">
                                    ({toolboxMeetingByIdData?.toolbox_id})
                                  </span>
                                </h4>
                              </div>

                              <div className="hstack gap-3 flex-wrap">
                                {toolboxMeetingByIdData?.report_status_name ?
                                  <>
                                    <div className="vr"></div>
                                    <div>
                                      Submission Status :{" "}
                                      <BadgeStatus value={toolboxMeetingByIdData?.report_status_name} color={STATUS_COLORS[toolboxMeetingByIdData?.report_status_name]} />
                                    </div>
                                  </>
                                  : null}
                                {toolboxMeetingByIdData?.approval_status_name ?
                                  <>
                                    <div className="vr"></div>
                                    <div>
                                      Approval Status :{" "}
                                      <BadgeStatus value={toolboxMeetingByIdData?.approval_status_name} color={STATUS_COLORS[toolboxMeetingByIdData?.approval_status_name]} />
                                    </div>
                                  </>
                                  : null}
                                <div className="vr"></div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    <Nav
                      className="nav-tabs-custom border-bottom-0"
                      role="tablist"
                      style={{ background: "lavender" }}
                    >
                      {(IsValidPermissions(['VIEW_ALL_TOOLBOX_MEETING']) || IsValidPermissions(['VIEW_ONLY_TOOLBOX_MEETING'])) &&
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "1" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("1");
                          }}
                          href="#"
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      }
                      {/* <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "2" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("2");
                          }}
                          href="#"
                        >
                          Approval
                        </NavLink>
                      </NavItem> */}
                      {/* <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "3" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("3");
                          }}
                          href="#"
                        >
                          Documents
                        </NavLink>
                      </NavItem> */}
                      {/* <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "4" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("4");
                          }}
                          href="#"
                        >
                          Activities
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1">
                  <OverviewTab toolboxMeetingByIdData={toolboxMeetingByIdData} />
                </TabPane>
                {/* <TabPane tabId="2">
                  <SafetyAppOverviewTab
                    projectData={approvalData}
                    refetchProject={refetchProject}
                    confirmCloseModal={confirmCloseModal}
                    confirmRevokeModal={confirmRevokeModal}
                    toggleConfirmCloseModal={toggleConfirmCloseModal}
                    toggleConfirmRevokeModal={toggleConfirmRevokeModal}
                  />
                </TabPane> */}
                {/* <TabPane tabId="3">
                  <DocumentsTab getHomeworkPermitById={getHomeworkPermitById} />
                </TabPane> */}
                {/* <TabPane tabId="4">
                  <ActivitiesTab
                    activities={auditLogs}
                    isLoading={auditLogsLoading}
                  />
                </TabPane> */}

              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Summary;
