import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label, Input, Form, Spinner, Table, Col } from "reactstrap";
import { useParams } from "react-router-dom";

// Define types for the form values
interface Question {
  questionnaire_key: number;
  question: string;
  answer: string; // Yes or No
  sort_id: number;
}

interface FormValues {
  questions: Question[];
}

interface Step2QuestionnaireProps {
  previousStep: () => void;

  formik: any;
  permitData: any;
  refetchPermit: any;
  questionnaireData: any;
  setInitialQuestions: any;
  patchisLoading: boolean;
  questionLoading: boolean;
  isPermitLoading: boolean;
}

const Step2Questionnaire: React.FC<Step2QuestionnaireProps> = ({
  previousStep,

  formik,
  permitData,
  refetchPermit,
  questionnaireData,
  setInitialQuestions,
  patchisLoading,
  questionLoading,
  isPermitLoading,
}) => {
  const { hwPermitNo } = useParams<{ hwPermitNo: string }>();

  // useEffect(() => {
  //   if (hwPermitNo) {
  //     refetchHotWorkPermit();
  //   }
  // }, [hwPermitNo, refetchHotWorkPermit]);

  // Populate initialQuestions state once questionnaireData is available
  useEffect(() => {
    if (questionnaireData) {
      const sortedQuestions = questionnaireData
        .map((q: any) => ({
          questionnaire_key: q.id,
          question: q.question,
          answer: "",
          sort_id: q.sort_id, // Include sort_id in the mapped questions
        }))
        .sort((a: { sort_id: number }, b: { sort_id: number }) => a.sort_id - b.sort_id);

      setInitialQuestions(sortedQuestions);
    }
  }, [questionnaireData]);


  // Set formik values based on hwPermitData
  useEffect(() => {
    if (permitData?.questionnaires?.length) {
      // Map the hwPermitData questionnaires to formik values
      const updatedQuestions = permitData.questionnaires.map((q: any) => ({
        questionnaire_key: q.questionnaire_key,
        question: q.question,
        answer: q.answer || "", // If there's an answer already, use it; otherwise, default to ""
        sort_id: q.sort_id,
      }));
      setInitialQuestions(updatedQuestions);
    }
  }, [permitData]);

  // Update the previousStep function to refetch data
  const handlePreviousStep = () => {
    refetchPermit();
    previousStep();
  };

  // Handle loading state
  if (questionLoading || isPermitLoading) {
    return <Spinner color="primary" />;
  }

  // If no data is returned, display an error message
  if (!questionnaireData || questionnaireData.length === 0) {
    return <div>No questions available</div>;
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Table className="table table-striped">
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
          </tr>
        </thead>
        <tbody>
          {formik.values.questions.map((q: any, index: number) => (
            <tr key={q.questionnaire_key}>
              <td>{q.question}</td>
              <td>
                <FormGroup check inline>
                  <Label check className="mr-2">
                    <Input
                      type="radio"
                      name={`questions[${index}].answer`}
                      value="Yes"
                      checked={formik.values.questions[index].answer === "Yes"}
                      onChange={formik.handleChange}
                    />
                    {" "} Yes
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name={`questions[${index}].answer`}
                      value="No"
                      checked={formik.values.questions[index].answer === "No"}
                      onChange={formik.handleChange}
                    />
                    {" "} No
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name={`questions[${index}].answer`}
                      value="NA"
                      checked={formik.values.questions[index].answer === "NA"}
                      onChange={formik.handleChange}
                    />
                    {" "} N/A
                  </Label>
                </FormGroup>
                {formik.touched.questions &&
                  formik.errors.questions &&
                  formik.errors.questions[index] &&
                  typeof formik.errors.questions[index] === "object" &&
                  formik.errors.questions[index].answer && (
                    <div className="text-danger">
                      {formik.errors.questions[index].answer}
                    </div>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <hr className="mt-4" />
      <Col md={12} className="mt-2">
        <FormGroup>
          <Label for="comments">
            Comments <span className="text-danger">*</span>
          </Label>
          <Input
            id="comments"
            name="comments"
            type="textarea"
            onChange={formik.handleChange}
            value={formik.values.comments}
            invalid={!!formik.errors.comments}
          />
          {formik.errors.comments && (
                <div className="text-danger">{formik.errors.comments}</div>
              )}
        </FormGroup>
      </Col>

      <div className="d-flex justify-content-between">
        <Button color="secondary" onClick={handlePreviousStep}>
          Previous
        </Button>
        <Button
          color="primary"
          disabled={patchisLoading || questionLoading}
          type="submit"
        >
          {patchisLoading ? "Loading..." : "Submit & Next"}
        </Button>
      </div>
    </Form>

  );
};

export default Step2Questionnaire;
