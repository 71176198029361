import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";

export const safetyAppSlice = createApi({
  reducerPath: "safetyApp",
  baseQuery: customBaseQuery,
  tagTypes: [
    "GetHomeWorkPermit",
    "GetHomeworkPermitByNumber",
    "GetHotworkPermit",
    "GetHotworkPermitByNumber",
    "GetGeneralworkPermit",
    "GetGeneralworkPermitByNumber",
    "GetWorkAtHeightPermit",
    "GetWorkAtHeightPermitByNumber",
    "GetExcavationPermitByNumber",
    "GetExcavationPermit",
    "GetLiftingWorkPermit",
    "GetLiftingWorkPermitByNumber",
    "GetConfinedSpacePermit",
    "GetConfinedSpacePermitByNumber",
    "GetToolboxMeeting",
    "ToolboxMeeting",
  ],
  endpoints: (builder) => ({
    //---HomeWork Permit EndPoints
    getHomeworkPermitView: builder.query({
      query: () => "/safety_app/api/v1/homework_permit_view/",
      providesTags: ["GetHomeWorkPermit"],
    }),
    getHomeworkPermitByNumber: builder.query({
      query: (hw_permit_no) =>
        `/safety_app/api/v1/homework_permit_view/${hw_permit_no}/`,
      providesTags: (result, error, hw_permit_no) => [
        { type: "GetHomeworkPermitByNumber", id: hw_permit_no },
      ],
    }),
    createHomeworkPermit: builder.mutation({
      query: (newPermitData) => ({
        url: "/safety_app/api/v1/homework_permit_create_update/",
        method: "POST",
        body: newPermitData,
      }),
    }),
    updateHomeworkPermit: builder.mutation({
      query: ({ hw_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/homework_permit_create_update/${hw_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
    }),

    // New endpoints for homework permit questionnaire key
    getHomeworkPermitQuestionnaireKeys: builder.query({
      query: () => "/safety_app/api/v1/homework_permit_questionnaire_key/",
    }),
    getHomeworkPermitQuestionnaireKeyById: builder.query({
      query: (id) =>
        `/safety_app/api/v1/homework_permit_questionnaire_key/${id}/`,
    }),
    createHomeworkPermitQuestionnaireKey: builder.mutation({
      query: (newQuestionnaireKeyData) => ({
        url: "/safety_app/api/v1/homework_permit_questionnaire_key/",
        method: "POST",
        body: newQuestionnaireKeyData,
      }),
    }),
    updateHomeworkPermitQuestionnaireKeyByHwPermitNo: builder.mutation({
      query: ({ hw_permit_no, updatedQuestionnaireKeyData }) => ({
        url: `/safety_app/api/v1/homework_permit_questionnaire_update/${hw_permit_no}/`,
        method: "PATCH",
        body: updatedQuestionnaireKeyData,
      }),
    }),
    updateHomeworkPermitToolboxMeetingByHwPermitNo: builder.mutation({
      query: ({ hw_permit_no, updatedToolboxMeetingData }) => ({
        url: `/safety_app/api/v1/homework_permit_toolbox_meeting/${hw_permit_no}/`,
        method: "PATCH",
        body: updatedToolboxMeetingData,
      }),
    }),
    generateHomeworkPermitPDF: builder.mutation({
      query: (hw_permit_no) => ({
        url: `/safety_app/api/v1/generate-pdf/${hw_permit_no}`,
        method: "GET",
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${hw_permit_no}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        },
      }),
    }),
    uploadHomeworkPermitDocument: builder.mutation({
      query: ({ hw_permit_no, documentData }) => ({
        url: `/safety_app/api/v1/homework_permit_document_update/${hw_permit_no}/`,
        method: "PATCH",
        body: documentData,
      }),
    }),
    deleteHomeworkPermitDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/safety_app/api/v1/homework_permit_document_delete/${id}/`,
        method: "DELETE",
      }),
    }),

    updateHomeworkWorkAreaAllocation: builder.mutation({
      query: ({ hw_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/homework_work_area_allocation_update/${hw_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
    }),
    deleteHomeworkWorkAreaAllocation: builder.mutation({
      query: ({
        sectionId,
        employeeId,
        type,
      }: {
        sectionId: number;
        employeeId: number | null;
        type: "employee" | "section";
      }) => {
        // Conditional URL based on type
        const url =
          type === "section"
            ? `/safety_app/api/v1/homework_work_area_allocation_delete/${sectionId}?type=section`
            : `/safety_app/api/v1/homework_work_area_allocation_delete/${sectionId}?type=employee&employee_id=${employeeId}`;

        return {
          url,
          method: "DELETE",
        };
      },
    }),

    // --- Hotwork Endpoints ---
    getHotworkPermitView: builder.query({
      query: () => "/safety_app/api/v1/hotwork_permit_view/",
      providesTags: ["GetHotworkPermit"],
    }),
    getHotworkPermitByNumber: builder.query({
      query: (hotwork_permit_no) =>
        `/safety_app/api/v1/hotwork_permit_view/${hotwork_permit_no}/`,
      providesTags: (result, error, hotwork_permit_no) => [
        { type: "GetHotworkPermitByNumber", id: hotwork_permit_no },
      ],
    }),
    createHotworkPermit: builder.mutation({
      query: (newPermitData) => ({
        url: "/safety_app/api/v1/hotwork_permit_create_update/",
        method: "POST",
        body: newPermitData,
      }),
      invalidatesTags: ["GetHotworkPermit"],
    }),
    updateHotworkPermit: builder.mutation({
      query: ({ hw_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/hotwork_permit_create_update/${hw_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
      invalidatesTags: (result, error, { hw_permit_no }) => [
        { type: "GetHotworkPermitByNumber", id: hw_permit_no },
      ],
    }),
    uploadHotworkPermitDocument: builder.mutation({
      query: ({ hw_permit_no, documentData }) => ({
        url: `/safety_app/api/v1/hotwork_permit_document_update/${hw_permit_no}/`,
        method: "PATCH",
        body: documentData,
      }),
    }),
    deleteHotworkPermitDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/safety_app/api/v1/hotwork_permit_document_delete/${id}/`,
        method: "DELETE",
      }),
    }),
    updateHotworkWorkAreaAllocation: builder.mutation({
      query: ({ hw_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/hotwork_work_area_allocation/${hw_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
    }),
    deleteHotworkWorkAreaAllocation: builder.mutation({
      query: ({
        sectionId,
        employeeId,
        type,
      }: {
        sectionId: number;
        employeeId: number | null;
        type: "employee" | "section";
      }) => {
        // Conditional URL based on type
        const url =
          type === "section"
            ? `/safety_app/api/v1/hotwork_area_allocation_delete/${sectionId}?type=section`
            : `/safety_app/api/v1/hotwork_area_allocation_delete/${sectionId}?type=employee&employee_id=${employeeId}`;

        return {
          url,
          method: "DELETE",
        };
      },
    }),
    getHotworkQuestionnaireKeys: builder.query({
      query: () => "/safety_app/api/v1/hotwork_permit_questionnaire_key/",
    }),
    getHotworkQuestionnaireKeyById: builder.query({
      query: (id) =>
        `/safety_app/api/v1/hotwork_permit_questionnaire_key/${id}/`,
    }),
    createHotworkQuestionnaireKey: builder.mutation({
      query: (newQuestionnaireKeyData) => ({
        url: "/safety_app/api/v1/hotwork_permit_questionnaire_key/",
        method: "POST",
        body: newQuestionnaireKeyData,
      }),
    }),
    updateHotworkQuestionnaireKeyById: builder.mutation({
      query: ({ id, updatedQuestionnaireKeyData }) => ({
        url: `/safety_app/api/v1/hotwork_permit_questionnaire_key/${id}/`,
        method: "PATCH",
        body: updatedQuestionnaireKeyData,
      }),
    }),
    updateHotworkQuestionnaireByPermitNo: builder.mutation({
      query: ({ hw_permit_no, updatedQuestionnaireKeyData }) => ({
        url: `/safety_app/api/v1/hotwork_permit_questionnaire_update/${hw_permit_no}/`,
        method: "PATCH",
        body: updatedQuestionnaireKeyData,
      }),
      // invalidatesTags: (result, error, { hw_permit_no }) => [
      //   { type: "GetHotworkPermitByNumber", id: hw_permit_no },
      // ],
    }),

    //GeneralWork Permit EndPoints
    getGeneralworkPermitView: builder.query({
      query: () => "/safety_app/api/v1/generalwork_permit_view/",
      providesTags: ["GetGeneralworkPermit"],
    }),
    getGeneralworkPermitByNumber: builder.query({
      query: (generalwork_permit_no) =>
        `/safety_app/api/v1/generalwork_permit_view/${generalwork_permit_no}/`,
      providesTags: (result, error, generalwork_permit_no) => [
        { type: "GetGeneralworkPermitByNumber", id: generalwork_permit_no },
      ],
    }),
    createGeneralworkPermit: builder.mutation({
      query: (newPermitData) => ({
        url: "/safety_app/api/v1/generalwork_permit_create_update/",
        method: "POST",
        body: newPermitData,
      }),
      invalidatesTags: ["GetGeneralworkPermit"],
    }),
    updateGeneralworkPermit: builder.mutation({
      query: ({ gw_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/generalwork_permit_create_update/${gw_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
      invalidatesTags: (result, error, { gw_permit_no }) => [
        { type: "GetGeneralworkPermitByNumber", id: gw_permit_no },
      ],
    }),
    uploadGeneralworkPermitDocument: builder.mutation({
      query: ({ gw_permit_no, documentData }) => ({
        url: `/safety_app/api/v1/generalwork_permit_document_update/${gw_permit_no}/`,
        method: "PATCH",
        body: documentData,
      }),
    }),
    deleteGeneralworkPermitDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/safety_app/api/v1/generalwork_permit_document_delete/${id}/`,
        method: "DELETE",
      }),
    }),
    updateGeneralworkWorkAreaAllocation: builder.mutation({
      query: ({ gw_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/generalwork_work_area_allocation/${gw_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
    }),
    deleteGeneralworkWorkAreaAllocation: builder.mutation({
      query: ({
        sectionId,
        employeeId,
        type,
      }: {
        sectionId: number;
        employeeId: number | null;
        type: "employee" | "section";
      }) => {
        // Conditional URL based on type
        const url =
          type === "section"
            ? `/safety_app/api/v1/generalwork_work_area_allocation_delete/${sectionId}?type=section`
            : `/safety_app/api/v1/generalwork_work_area_allocation_delete/${sectionId}?type=employee&employee_id=${employeeId}`;

        return {
          url,
          method: "DELETE",
        };
      },
    }),
    getGeneralworkQuestionnaireKeys: builder.query({
      query: () => "/safety_app/api/v1/generalwork_permit_questionnaire_key/",
    }),
    getGeneralworkQuestionnaireKeyById: builder.query({
      query: (id) =>
        `/safety_app/api/v1/generalwork_permit_questionnaire_key/${id}/`,
    }),
    createGeneralworkQuestionnaireKey: builder.mutation({
      query: (newQuestionnaireKeyData) => ({
        url: "/safety_app/api/v1/generalwork_permit_questionnaire_key/",
        method: "POST",
        body: newQuestionnaireKeyData,
      }),
    }),
    updateGeneralworkQuestionnaireByPermitNo: builder.mutation({
      query: ({ gw_permit_no, updatedQuestionnaireKeyData }) => ({
        url: `/safety_app/api/v1/generalwork_permit_questionnaire_update/${gw_permit_no}/`,
        method: "PATCH",
        body: updatedQuestionnaireKeyData,
      }),
      // invalidatesTags: (result, error, { gw_permit_no }) => [
      //   { type: "GetGeneralworkPermitByNumber", id: gw_permit_no },
      // ],
    }),

    //WorkAtHeight End Points

    getWorkAtHeightPermitView: builder.query({
      query: () => "/safety_app/api/v1/workatheight_permit_view/",
      providesTags: ["GetWorkAtHeightPermit"],
    }),
    getWorkAtHeightPermitByNumber: builder.query({
      query: (wh_permit_no) =>
        `/safety_app/api/v1/workatheight_permit_view/${wh_permit_no}/`,
      providesTags: (result, error, wh_permit_no) => [
        { type: "GetWorkAtHeightPermitByNumber", id: wh_permit_no },
      ],
    }),
    createWorkAtHeightPermit: builder.mutation({
      query: (newPermitData) => ({
        url: "/safety_app/api/v1/workatheight_permit_create_update/",
        method: "POST",
        body: newPermitData,
      }),
      invalidatesTags: ["GetWorkAtHeightPermit"],
    }),
    updateWorkAtHeightPermit: builder.mutation({
      query: ({ wh_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/workatheight_permit_create_update/${wh_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
      invalidatesTags: (result, error, { wh_permit_no }) => [
        { type: "GetWorkAtHeightPermitByNumber", id: wh_permit_no },
      ],
    }),
    uploadWorkAtHeightPermitDocument: builder.mutation({
      query: ({ wh_permit_no, documentData }) => ({
        url: `/safety_app/api/v1/workatheight_permit_document_update/${wh_permit_no}/`,
        method: "PATCH",
        body: documentData,
      }),
    }),
    deleteWorkAtHeightPermitDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/safety_app/api/v1/workatheight_permit_document_delete/${id}/`,
        method: "DELETE",
      }),
    }),
    updateWorkAtHeightWorkAreaAllocation: builder.mutation({
      query: ({ wh_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/workatheight_area_allocation/${wh_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
    }),
    deleteWorkAtHeightWorkAreaAllocation: builder.mutation({
      query: ({
        sectionId,
        employeeId,
        type,
      }: {
        sectionId: number;
        employeeId: number | null;
        type: "employee" | "section";
      }) => {
        // Conditional URL based on type
        const url =
          type === "section"
            ? `/safety_app/api/v1/workatheight_area_allocation_delete/${sectionId}?type=section`
            : `/safety_app/api/v1/workatheight_area_allocation_delete/${sectionId}?type=employee&employee_id=${employeeId}`;

        return {
          url,
          method: "DELETE",
        };
      },
    }),
    getWorkAtHeightQuestionnaireKeys: builder.query({
      query: () => "/safety_app/api/v1/workatheight_permit_questionnaire_key/",
    }),
    getWorkAtHeightQuestionnaireKeyById: builder.query({
      query: (id) =>
        `/safety_app/api/v1/workatheight_permit_questionnaire_key/${id}/`,
    }),
    createWorkAtHeightQuestionnaireKey: builder.mutation({
      query: (newQuestionnaireKeyData) => ({
        url: "/safety_app/api/v1/workatheight_permit_questionnaire_key/",
        method: "POST",
        body: newQuestionnaireKeyData,
      }),
    }),
    updateWorkAtHeightQuestionnaireByPermitNo: builder.mutation({
      query: ({ wh_permit_no, updatedQuestionnaireKeyData }) => ({
        url: `/safety_app/api/v1/workatheight_permit_questionnaire_update/${wh_permit_no}/`,
        method: "PATCH",
        body: updatedQuestionnaireKeyData,
      }),
      // invalidatesTags: (result, error, { wh_permit_no }) => [
      //   { type: "GetWorkAtHeightPermitByNumber", id: wh_permit_no },
      // ],
    }),

    // Excavation Permit Endpoints
    getExcavationPermitView: builder.query({
      query: () => "/safety_app/api/v1/excavation_permit_view/",
      providesTags: ["GetExcavationPermit"],
    }),
    getExcavationPermitByNumber: builder.query({
      query: (excavation_permit_no) =>
        `/safety_app/api/v1/excavation_permit_view/${excavation_permit_no}/`,
      providesTags: (result, error, excavation_permit_no) => [
        { type: "GetExcavationPermitByNumber", id: excavation_permit_no },
      ],
    }),
    createExcavationPermit: builder.mutation({
      query: (newPermitData) => ({
        url: "/safety_app/api/v1/excavation_permit_create_update/",
        method: "POST",
        body: newPermitData,
      }),
      invalidatesTags: ["GetExcavationPermit"],
    }),
    updateExcavationPermit: builder.mutation({
      query: ({ ew_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/excavation_permit_create_update/${ew_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
      invalidatesTags: (result, error, { ew_permit_no }) => [
        { type: "GetExcavationPermitByNumber", id: ew_permit_no },
      ],
    }),
    uploadExcavationPermitDocument: builder.mutation({
      query: ({ ew_permit_no, documentData }) => ({
        url: `/safety_app/api/v1/excavation_permit_document_update/${ew_permit_no}/`,
        method: "PATCH",
        body: documentData,
      }),
    }),
    deleteExcavationPermitDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/safety_app/api/v1/excavation_permit_document_delete/${id}/`,
        method: "DELETE",
      }),
    }),
    updateExcavationWorkAreaAllocation: builder.mutation({
      query: ({ ew_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/excavation_area_allocation/${ew_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
    }),
    deleteExcavationWorkAreaAllocation: builder.mutation({
      query: ({
        sectionId,
        employeeId,
        type,
      }: {
        sectionId: number;
        employeeId: number | null;
        type: "employee" | "section";
      }) => {
        // Conditional URL based on type
        const url =
          type === "section"
            ? `/safety_app/api/v1/excavation_area_allocation_delete/${sectionId}?type=section`
            : `/safety_app/api/v1/excavation_area_allocation_delete/${sectionId}?type=employee&employee_id=${employeeId}`;

        return {
          url,
          method: "DELETE",
        };
      },
    }),
    getExcavationQuestionnaireKeys: builder.query({
      query: () => "/safety_app/api/v1/excavation_permit_questionnaire_key/",
    }),
    getExcavationQuestionnaireKeyById: builder.query({
      query: (id) =>
        `/safety_app/api/v1/excavation_permit_questionnaire_key/${id}/`,
    }),
    createExcavationQuestionnaireKey: builder.mutation({
      query: (newQuestionnaireKeyData) => ({
        url: "/safety_app/api/v1/excavation_permit_questionnaire_key/",
        method: "POST",
        body: newQuestionnaireKeyData,
      }),
    }),
    updateExcavationQuestionnaireByPermitNo: builder.mutation({
      query: ({ ew_permit_no, updatedQuestionnaireKeyData }) => ({
        url: `/safety_app/api/v1/excavation_permit_questionnaire_update/${ew_permit_no}/`,
        method: "PATCH",
        body: updatedQuestionnaireKeyData,
      }),
      // invalidatesTags: (result, error, { ew_permit_no }) => [
      //   { type: "GetExcavationPermitByNumber", id: ew_permit_no },
      // ],
    }),

    // --- Lifting Work Endpoints ---
    getLiftingWorkPermitView: builder.query({
      query: () => "/safety_app/api/v1/lifting_permit_view/",
      providesTags: ["GetLiftingWorkPermit"],
    }),
    getLiftingWorkPermitByNumber: builder.query({
      query: (lp_permit_no) =>
        `/safety_app/api/v1/lifting_permit_view/${lp_permit_no}/`,
      providesTags: (result, error, lp_permit_no) => [
        { type: "GetLiftingWorkPermitByNumber", id: lp_permit_no },
      ],
    }),
    createLiftingWorkPermit: builder.mutation({
      query: (newPermitData) => ({
        url: "/safety_app/api/v1/lifting_permit_create_update/",
        method: "POST",
        body: newPermitData,
      }),
      invalidatesTags: ["GetLiftingWorkPermit"],
    }),
    updateLiftingWorkPermit: builder.mutation({
      query: ({ lp_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/lifting_permit_create_update/${lp_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
      invalidatesTags: (result, error, { lp_permit_no }) => [
        { type: "GetLiftingWorkPermitByNumber", id: lp_permit_no },
      ],
    }),
    uploadLiftingWorkPermitDocument: builder.mutation({
      query: ({ lp_permit_no, documentData }) => ({
        url: `/safety_app/api/v1/lifting_permit_document_update/${lp_permit_no}/`,
        method: "PATCH",
        body: documentData,
      }),
    }),
    deleteLiftingWorkPermitDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/safety_app/api/v1/lifting_permit_document_delete/${id}/`,
        method: "DELETE",
      }),
    }),
    updateLiftingWorkAreaAllocation: builder.mutation({
      query: ({ lp_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/lifting_area_allocation/${lp_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
    }),
    deleteLiftingWorkAreaAllocation: builder.mutation({
      query: ({
        sectionId,
        employeeId,
        type,
      }: {
        sectionId: number;
        employeeId: number | null;
        type: "employee" | "section";
      }) => {
        // Conditional URL based on type
        const url =
          type === "section"
            ? `/safety_app/api/v1/lifting_area_allocation_delete/${sectionId}?type=section`
            : `/safety_app/api/v1/lifting_area_allocation_delete/${sectionId}?type=employee&employee_id=${employeeId}`;

        return {
          url,
          method: "DELETE",
        };
      },
    }),
    getLiftingWorkQuestionnaireKeys: builder.query({
      query: () => "/safety_app/api/v1/lifting_permit_questionnaire_key/",
    }),
    getLiftingWorkQuestionnaireKeyById: builder.query({
      query: (id) =>
        `/safety_app/api/v1/lifting_permit_questionnaire_key/${id}/`,
    }),
    createLiftingWorkQuestionnaireKey: builder.mutation({
      query: (newQuestionnaireKeyData) => ({
        url: "/safety_app/api/v1/lifting_permit_questionnaire_key/",
        method: "POST",
        body: newQuestionnaireKeyData,
      }),
    }),
    updateLiftingWorkQuestionnaireByPermitNo: builder.mutation({
      query: ({ lp_permit_no, updatedQuestionnaireKeyData }) => ({
        url: `/safety_app/api/v1/lifting_permit_questionnaire_update/${lp_permit_no}/`,
        method: "PATCH",
        body: updatedQuestionnaireKeyData,
      }),
      // invalidatesTags: (result, error, { lp_permit_no }) => [
      //   { type: "GetLiftingWorkPermitByNumber", id: lp_permit_no },
      // ],
    }),

    //----Confined Space Permit----//

    getConfinedSpacePermitView: builder.query({
      query: () => "/safety_app/api/v1/confined_space_permit_view/",
      providesTags: ["GetConfinedSpacePermit"],
    }),
    getConfinedSpacePermitByNumber: builder.query({
      query: (confined_space_permit_no) =>
        `/safety_app/api/v1/confined_space_permit_view/${confined_space_permit_no}/`,
      providesTags: (result, error, confined_space_permit_no) => [
        {
          type: "GetConfinedSpacePermitByNumber",
          id: confined_space_permit_no,
        },
      ],
    }),
    createConfinedSpacePermit: builder.mutation({
      query: (newPermitData) => ({
        url: "/safety_app/api/v1/confined_space_permit_create_update/",
        method: "POST",
        body: newPermitData,
      }),
      invalidatesTags: ["GetConfinedSpacePermit"],
    }),
    updateConfinedSpacePermit: builder.mutation({
      query: ({ cs_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/confined_space_permit_create_update/${cs_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
      invalidatesTags: (result, error, { cs_permit_no }) => [
        { type: "GetConfinedSpacePermitByNumber", id: cs_permit_no },
      ],
    }),
    uploadConfinedSpacePermitDocument: builder.mutation({
      query: ({ cs_permit_no, documentData }) => ({
        url: `/safety_app/api/v1/confined_space_permit_document_update/${cs_permit_no}/`,
        method: "PATCH",
        body: documentData,
      }),
    }),
    deleteConfinedSpacePermitDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/safety_app/api/v1/confined_space_permit_document_delete/${id}/`,
        method: "DELETE",
      }),
    }),
    updateConfinedSpaceWorkAreaAllocation: builder.mutation({
      query: ({ cs_permit_no, updatedPermitData }) => ({
        url: `/safety_app/api/v1/confined_space_area_allocation/${cs_permit_no}/`,
        method: "PATCH",
        body: updatedPermitData,
      }),
    }),
    deleteConfinedSpaceWorkAreaAllocation: builder.mutation({
      query: ({
        sectionId,
        employeeId,
        type,
      }: {
        sectionId: number;
        employeeId: number | null;
        type: "employee" | "section";
      }) => {
        // Conditional URL based on type
        const url =
          type === "section"
            ? `/safety_app/api/v1/confined_space_area_allocation_delete/${sectionId}?type=section`
            : `-/safety_app/api/v1/confined_space_area_allocation_delete/${sectionId}?type=employee&employee_id=${employeeId}`;

        return {
          url,
          method: "DELETE",
        };
      },
    }),
    getConfinedSpaceQuestionnaireKeys: builder.query({
      query: () =>
        "/safety_app/api/v1/confined_space_permit_questionnaire_key/",
    }),
    getConfinedSpaceQuestionnaireKeyById: builder.query({
      query: (id) =>
        `/safety_app/api/v1/confined_space_permit_questionnaire_key/${id}/`,
    }),
    createConfinedSpaceQuestionnaireKey: builder.mutation({
      query: (newQuestionnaireKeyData) => ({
        url: "/safety_app/api/v1/confined_space_permit_questionnaire_key/",
        method: "POST",
        body: newQuestionnaireKeyData,
      }),
    }),
    updateConfinedSpaceQuestionnaireByPermitNo: builder.mutation({
      query: ({ cs_permit_no, updatedQuestionnaireKeyData }) => ({
        url: `/safety_app/api/v1/confined_space_permit_questionnaire_update/${cs_permit_no}/`,
        method: "PATCH",
        body: updatedQuestionnaireKeyData,
      }),
      // invalidatesTags: (result, error, { cs_permit_no }) => [
      //   { type: "GetConfinedSpacePermitByNumber", id: cs_permit_no },
      // ],
    }),
    //--ToolBox-Meeting
    getToolboxMeetingView: builder.query({
      query: () => "/safety_app/api/v1/toolbox_meeting_view/",
      providesTags: ["GetToolboxMeeting"],
    }),
    getToolboxMeetingByFilter: builder.query({
      query: ({project_id, start_date, end_date}) => 
        `/safety_app/api/v1/toolbox_meeting_drop_down/?project=${project_id}&start_date_of_work=${start_date}&end_date_of_work=${end_date}`,
      providesTags: ["GetToolboxMeeting"],
    }),
    getToolboxMeetingById: builder.query({
      query: (toolbox_id) =>
        `/safety_app/api/v1/toolbox_meeting_view/${toolbox_id}/`,
      providesTags: (result, error, toolbox_id) => [
        { type: "ToolboxMeeting", id: toolbox_id },
      ],
    }),
    createToolboxMeeting: builder.mutation({
      query: (newMeetingData) => ({
        url: `/safety_app/api/v1/toolbox_meeting_create_update/`,
        method: "POST",
        body: newMeetingData,
      }),
      invalidatesTags: ["ToolboxMeeting"],
    }),
    updateToolboxMeeting: builder.mutation({
      query: ({ toolbox_id, updatedMeetingData }) => ({
        url: `/safety_app/api/v1/toolbox_meeting_create_update/${toolbox_id}/`,
        method: "PATCH",
        body: updatedMeetingData,
      }),
      invalidatesTags: (result, error, { toolbox_id }) => [
        { type: "ToolboxMeeting", id: toolbox_id },
      ],
    }),
    updateToolboxAttendance: builder.mutation({
      query: ({ toolbox_id, attendanceData }) => ({
        url: `/safety_app/api/v1/toolbox_attendance_update/${toolbox_id}/`,
        method: "PATCH",
        body: attendanceData,
      }),
      invalidatesTags: (result, error, { toolbox_id }) => [
        { type: "ToolboxMeeting", id: toolbox_id },
      ],
    }),
    linkToolboxMeeting: builder.mutation({
      query: ({ toolbox_id, linkData }) => ({
        url: `/safety_app/api/v1/link_toolbox_meeting/${toolbox_id}/`,
        method: "PATCH",
        body: linkData,
      }),
      invalidatesTags: (result, error, { toolbox_id }) => [
        { type: "ToolboxMeeting", id: toolbox_id },
      ],
    }),
    deleteToolboxAttendance: builder.mutation({
      query: ({ id }) => ({
        url: `/safety_app/api/v1/toolbox_attendance_delete/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "ToolboxMeeting" }],
    }),
  }),
});

export const {
  //Home Work Permit
  useGetHomeworkPermitViewQuery,
  useGetHomeworkPermitByNumberQuery,
  useCreateHomeworkPermitMutation,
  useUpdateHomeworkPermitMutation,
  useGetHomeworkPermitQuestionnaireKeysQuery,
  useGetHomeworkPermitQuestionnaireKeyByIdQuery,
  useCreateHomeworkPermitQuestionnaireKeyMutation,
  useUpdateHomeworkPermitQuestionnaireKeyByHwPermitNoMutation,
  useUpdateHomeworkPermitToolboxMeetingByHwPermitNoMutation,
  useGenerateHomeworkPermitPDFMutation,
  useUploadHomeworkPermitDocumentMutation,
  useDeleteHomeworkPermitDocumentMutation,
  useUpdateHomeworkWorkAreaAllocationMutation,
  useDeleteHomeworkWorkAreaAllocationMutation,

  //Hot Work Permit
  useGetHotworkPermitViewQuery,
  useGetHotworkPermitByNumberQuery,
  useCreateHotworkPermitMutation,
  useUpdateHotworkPermitMutation,
  useUploadHotworkPermitDocumentMutation,
  useDeleteHotworkPermitDocumentMutation,
  useUpdateHotworkWorkAreaAllocationMutation,
  useDeleteHotworkWorkAreaAllocationMutation,
  useGetHotworkQuestionnaireKeysQuery,
  useGetHotworkQuestionnaireKeyByIdQuery,
  useCreateHotworkQuestionnaireKeyMutation,
  useUpdateHotworkQuestionnaireKeyByIdMutation,
  useUpdateHotworkQuestionnaireByPermitNoMutation,

  // Generalwork Permit Hooks
  useGetGeneralworkPermitViewQuery,
  useGetGeneralworkPermitByNumberQuery,
  useCreateGeneralworkPermitMutation,
  useUpdateGeneralworkPermitMutation,
  useUploadGeneralworkPermitDocumentMutation,
  useDeleteGeneralworkPermitDocumentMutation,
  useUpdateGeneralworkWorkAreaAllocationMutation,
  useDeleteGeneralworkWorkAreaAllocationMutation,
  useGetGeneralworkQuestionnaireKeysQuery,
  useGetGeneralworkQuestionnaireKeyByIdQuery,
  useCreateGeneralworkQuestionnaireKeyMutation,
  useUpdateGeneralworkQuestionnaireByPermitNoMutation,

  //WorkAtHeight Permit Hooks
  useGetWorkAtHeightPermitViewQuery,
  useGetWorkAtHeightPermitByNumberQuery,
  useCreateWorkAtHeightPermitMutation,
  useUpdateWorkAtHeightPermitMutation,
  useUploadWorkAtHeightPermitDocumentMutation,
  useDeleteWorkAtHeightPermitDocumentMutation,
  useUpdateWorkAtHeightWorkAreaAllocationMutation,
  useDeleteWorkAtHeightWorkAreaAllocationMutation,
  useGetWorkAtHeightQuestionnaireKeysQuery,
  useGetWorkAtHeightQuestionnaireKeyByIdQuery,
  useCreateWorkAtHeightQuestionnaireKeyMutation,
  useUpdateWorkAtHeightQuestionnaireByPermitNoMutation,

  // Excavation Permit Hooks
  useGetExcavationPermitViewQuery,
  useGetExcavationPermitByNumberQuery,
  useCreateExcavationPermitMutation,
  useUpdateExcavationPermitMutation,
  useUploadExcavationPermitDocumentMutation,
  useDeleteExcavationPermitDocumentMutation,
  useUpdateExcavationWorkAreaAllocationMutation,
  useDeleteExcavationWorkAreaAllocationMutation,
  useGetExcavationQuestionnaireKeysQuery,
  useGetExcavationQuestionnaireKeyByIdQuery,
  useCreateExcavationQuestionnaireKeyMutation,
  useUpdateExcavationQuestionnaireByPermitNoMutation,

  //Lifting Work Permit
  useGetLiftingWorkPermitViewQuery,
  useGetLiftingWorkPermitByNumberQuery,
  useCreateLiftingWorkPermitMutation,
  useUpdateLiftingWorkPermitMutation,
  useUploadLiftingWorkPermitDocumentMutation,
  useDeleteLiftingWorkPermitDocumentMutation,
  useUpdateLiftingWorkAreaAllocationMutation,
  useDeleteLiftingWorkAreaAllocationMutation,
  useGetLiftingWorkQuestionnaireKeysQuery,
  useGetLiftingWorkQuestionnaireKeyByIdQuery,
  useCreateLiftingWorkQuestionnaireKeyMutation,
  useUpdateLiftingWorkQuestionnaireByPermitNoMutation,

  //Confined Space Permit
  useGetConfinedSpacePermitViewQuery,
  useGetConfinedSpacePermitByNumberQuery,
  useCreateConfinedSpacePermitMutation,
  useUpdateConfinedSpacePermitMutation,
  useUploadConfinedSpacePermitDocumentMutation,
  useDeleteConfinedSpacePermitDocumentMutation,
  useUpdateConfinedSpaceWorkAreaAllocationMutation,
  useDeleteConfinedSpaceWorkAreaAllocationMutation,
  useGetConfinedSpaceQuestionnaireKeysQuery,
  useGetConfinedSpaceQuestionnaireKeyByIdQuery,
  useCreateConfinedSpaceQuestionnaireKeyMutation,
  useUpdateConfinedSpaceQuestionnaireByPermitNoMutation,

  useGetToolboxMeetingViewQuery,
  useGetToolboxMeetingByFilterQuery,
  useLazyGetToolboxMeetingByFilterQuery,
  useGetToolboxMeetingByIdQuery,
  useCreateToolboxMeetingMutation,
  useUpdateToolboxMeetingMutation,
  useUpdateToolboxAttendanceMutation,
  useLinkToolboxMeetingMutation,
  useDeleteToolboxAttendanceMutation,
} = safetyAppSlice;
