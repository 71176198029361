import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Table,
  Row,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  useGetDocumentsByEmployeeUuidQuery,
  useGetDocumentByIdQuery,
  usePatchDocumentByIdMutation,
  usePostDocumentMutation,
  useDeleteDocumentByIdMutation,
} from "slices/employee/employeeSlice";

import LoadingOverlay from "react-loading-overlay-ts";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

interface EmployeeDocumentProps {
  employee_uuid: string | undefined;
}

const EmployeeDocumentList: React.FC<EmployeeDocumentProps> = ({
  employee_uuid,
}) => {
  const {
    data: documents,
    isLoading,
    refetch,
  } = useGetDocumentsByEmployeeUuidQuery(employee_uuid!, {
    skip: !employee_uuid,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    description: "",
    name: "",
    attachment: null as File | null,
    expiry_date: "",
    employee_employee_uuid: employee_uuid || "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Document name is required"),
    description: Yup.string().required("Description is required"),
    expiry_date: Yup.date().required("Expiry date is required").nullable(),
    attachment: Yup.mixed().required("Please upload a document"),
  });

  const [postDocument, { isLoading: postLoading }] = usePostDocumentMutation();
  const [patchDocument, { isLoading: patchLoading }] =
    usePatchDocumentByIdMutation();
  const [deleteDocument, { isLoading: deleteLoading }] =
    useDeleteDocumentByIdMutation();

  const { data: documentDetails, isLoading: isDocumentLoading } =
    useGetDocumentByIdQuery(selectedDocumentId || "", {
      skip: !isEditing || !selectedDocumentId,
    });

  useEffect(() => {
    if (documentDetails) {
      setFormData({
        description: documentDetails.description || "",
        name: documentDetails.name || "",
        attachment: documentDetails.attachment || null,
        expiry_date: documentDetails.expiry_date || "",
        employee_employee_uuid: documentDetails.employee_employee_uuid || "",
      });
    }
  }, [documentDetails, isEditing]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files;

    if (fileInput && fileInput.length > 0) {
      formik.setFieldValue("attachment", fileInput[0]);
    }
  };
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddDocument = () => {
    setSelectedDocumentId(null);
    setIsEditing(false);
    handleModalToggle();
    setFormData({
      description: "",
      name: "",
      attachment: null as File | null,
      expiry_date: "",
      employee_employee_uuid: employee_uuid || "",
    });
  };

  const handleEditDocument = (id: string) => {
    setSelectedDocumentId(id);
    setIsEditing(true);
    handleModalToggle();
    setFormData({
      description: "",
      name: "",
      attachment: null as File | null,
      expiry_date: "",
      employee_employee_uuid: employee_uuid || "",
    });
  };

  const handleDeleteConfirmation = (id: string) => {
    setSelectedDocumentId(id);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedDocumentId) {
      try {
        await deleteDocument(selectedDocumentId).unwrap();
        refetch();
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const handleFileDownload = async (url: string) => {
    try {
      // Create a URL object
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      let filename = pathname.split("/").pop();

      // Handle the case where filename is undefined
      if (!filename) {
        console.error("Filename could not be extracted from the URL.");
        return; // Optionally handle this case differently
      }

      // Fetch the file
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      // Convert response to blob
      const blob = await response.blob();

      // Create an object URL for the blob
      const fileURL = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = filename; // Assign a string value
      link.click();

      // Cleanup: Revoke the object URL to free up memory
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      description: formData.description || "",
      expiry_date: formData.expiry_date || "",
      // attachment: null as File | null,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          ...values,
          employee_employee_uuid: employee_uuid || "",
        };

        if (isEditing && selectedDocumentId) {
          await patchDocument({
            id: selectedDocumentId,
            data: payload,
          }).unwrap();
        } else {
          await postDocument(payload);
        }

        refetch();
        handleModalToggle();
      } catch (error) {
        console.error("Error submitting document:", error);
      }
    },
    enableReinitialize: true,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
            {IsValidPermissions(['EDIT_MEMBERS']) &&
            <Button color="danger" onClick={handleAddDocument}>
              <i className="ri-upload-2-fill me-1 align-bottom"></i> Add
              Document
            </Button>
            }
          </div>
          <Row>
            <Col lg={12}>
              {documents && documents.length > 0 ? (
                <div className="table-responsive">
                  <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">File</th>
                        <th scope="col">File Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(documents || []).map((item: any) => {
                        const fileName = item.attachment
                          .split("/")
                          .pop()
                          .split("?")[0];
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();
                        const imageExtensions = ["png", "jpg", "jpeg"];
                        const iconClass = imageExtensions.includes(
                          fileExtension
                        )
                          ? "ri-image-line"
                          : "ri-folder-zip-line";

                        return (
                          <tr key={item.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm">
                                  <div
                                    className={`avatar-title bg-${
                                      iconClass.includes("image")
                                        ? "primary"
                                        : "secondary"
                                    }-subtle text-${
                                      iconClass.includes("image")
                                        ? "primary"
                                        : "secondary"
                                    } rounded fs-20`}
                                  >
                                    <i className={iconClass}></i>
                                  </div>
                                </div>
                                <div className="ms-3 flex-grow-1">
                                  <h6 className="fs-15 mb-0">
                                    <Link to="#">{fileName}</Link>
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td>{item?.name}</td>
                            <td>{item?.description}</td>
                            <td>{item?.expiry_date}</td>
                            <td>
                              <UncontrolledDropdown direction="start">
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-light btn-icon"
                                  id="dropdownMenuLink15"
                                  role="button"
                                >
                                  <i className="ri-equalizer-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() =>
                                      handleFileDownload(item.attachment_url)
                                    }
                                  >
                                    <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                    Download
                                  </DropdownItem>
                                  {/* <DropdownItem
                                    onClick={() => handleEditDocument(item.id)}
                                  >
                                    <i className="ri-edit-2-fill me-2 align-middle text-muted" />
                                    Edit
                                  </DropdownItem> */}
                                  <DropdownItem divider />
                                  {IsValidPermissions(['DELETE_MEMBERS']) &&
                                  <DropdownItem
                                    onClick={() =>
                                      handleDeleteConfirmation(item.id)
                                    }
                                  >
                                    <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                    Delete
                                  </DropdownItem>}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  No documents available.
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={isModalOpen} toggle={handleModalToggle} centered>
        <ModalHeader toggle={handleModalToggle}>
          {isEditing ? "Edit Document" : "Add Document"}
        </ModalHeader>
        <ModalBody>
          <LoadingOverlay active={isDocumentLoading} spinner text="Loading...">
            {/* Ensure form has onSubmit handler */}
            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label htmlFor="document-name">Document Name</Label>
                <Input
                  id="document-name"
                  name="name"
                  placeholder="Enter document name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  invalid={!!formik.errors.name}
                />
                {formik.errors.name && (
                  <FormFeedback>{formik.errors.name}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="document-description">Description</Label>
                <Input
                  type="textarea"
                  id="document-description"
                  name="description"
                  placeholder="Enter description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  invalid={!!formik.errors.description}
                />
                {formik.errors.description && (
                  <FormFeedback>{formik.errors.description}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="document-expiry-date">Expiry Date</Label>
                <Input
                  type="date"
                  id="document-expiry-date"
                  name="expiry_date"
                  onChange={formik.handleChange}
                  value={formik.values.expiry_date}
                  invalid={!!formik.errors.expiry_date}
                />
                {formik.errors.expiry_date && (
                  <FormFeedback>{formik.errors.expiry_date}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="document-attachment">Attachment</Label>
                <Input
                  type="file"
                  id="document-attachment"
                  name="attachment"
                  onChange={handleFileChange}
                  required
                  // invalid={!!formik.errors.attachment}
                />
                {/* {formik.errors.attachment && (
                  <FormFeedback>{formik.errors.attachment}</FormFeedback>
                )} */}
              </FormGroup>
              {/* Add a submit button within the form */}
              <Button
                color="primary"
                type="submit"
                disabled={postLoading || patchLoading}
              >
                {postLoading || patchLoading ? <Spinner size="sm" /> : "Submit"}
              </Button>
              &nbsp;&nbsp;
              <Button color="secondary" onClick={handleModalToggle}>
                Cancel
              </Button>
            </form>
          </LoadingOverlay>
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this document? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={handleConfirmDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EmployeeDocumentList;
