import React, { useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useGetToolboxMeetingViewQuery } from "slices/safetyApp/safetyappSlice";
import TableContainer from "Components/Common/TableContainer";
import Loader from "Components/Common/Loader";
import { Tooltip } from "react-tooltip";
import { BadgeStatus } from "helpers/badgeStatus";
import { formatDateTime } from "helpers/date_utils";
import { STATUS_COLORS } from "helpers/common_helper";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

const ToolBoxMeetingListView = () => {
  const { data: toolboxMeetings, isLoading } = useGetToolboxMeetingViewQuery(undefined);
  const navigate = useNavigate();

  // Define Columns for Table
  const columns = useMemo(
    () => [
      {
        Header: "Meeting ID",
        accessor: "toolbox_id",
        Cell: ({
          row: {
            original: { toolbox_id },
          },
        }: any) => (
          <Link
            to={`/toolbox-meeting-detail-view/${toolbox_id}/`}
            className="text-primary d-inline-block"
          >
            {toolbox_id}
          </Link>
        ),
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      {
        Header: "Start & End Date of Work",
        accessor: "start_date_of_work", // Start Date
        Cell: ({
          row: {
            original: { start_date_of_work, start_time_of_work, end_date_of_work, end_time_of_work },
          },
        }: any) => (<>{formatDateTime(start_date_of_work, 'date')} {formatDateTime(start_time_of_work, 'time')} <i className="las la-arrows-alt-h"></i> {formatDateTime(end_date_of_work, 'date')} {formatDateTime(end_time_of_work, 'time')}</>),
      },
      // {
      //   Header: "End Date of Work",
      //   accessor: "end_date_of_work", // End Date
      //   Cell: ({ value }: any) => formatDateTime(value, 'date'), // Format Date
      // },
      {
        Header: "Status",
        accessor: "report_status_name",
        Cell: (cell: { value: any }) => (
          <BadgeStatus value={cell.value} color={STATUS_COLORS[cell.value]} />
        ),
      },
      {
        Header: "Conducted By",
        accessor: "submited_by_name",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({
          row: {
            original: { toolbox_id },
          },
        }: any) => (
          <div className="d-flex gap-2">
            {(IsValidPermissions(['VIEW_ALL_TOOLBOX_MEETING']) || IsValidPermissions(['VIEW_ONLY_TOOLBOX_MEETING'])) &&
            <Link
              to={`/toolbox-meeting-detail-view/${toolbox_id}/`}
              className="text-primary"
            >
              <i className="ri-eye-fill fs-16"></i>
            </Link>
            }
            {(IsValidPermissions(['EDIT_TOOLBOX_MEETING'])) &&
            <Link
              to={`/toolbox-meeting-wizard/${toolbox_id}/`}
              className="text-warning"
            >
              <i className="ri-pencil-fill fs-16"></i>
            </Link>
            }
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".view-task-status-class"
        place="bottom"
        content="View"
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Toolbox Meeting List" pageTitle="Safety App" />
          <Row>
            <Col lg={12}>
              <Card id="toolboxMeetingList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm>
                      <div>
                        <h5 className="card-title mb-0">
                          Toolbox Meeting List
                        </h5>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div>
                      {(IsValidPermissions(['ADD_TOOLBOX_MEETING'])) &&
                        <Button color="success" onClick={()=>navigate("/toolbox-meeting-wizard/")}>
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add Toolbox Meeting
                        </Button>
                      }
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={toolboxMeetings?.results || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      className="custom-header-css"
                      isPagination={true}
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ToolBoxMeetingListView;
