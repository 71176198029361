import React, { useState } from "react";
import { Col, Row, Table, Button, Spinner } from "reactstrap";
import {
  useUpdateHomeworkWorkAreaAllocationMutation,
  useDeleteHomeworkWorkAreaAllocationMutation,
  useGetHomeworkPermitByNumberQuery,
} from "slices/safetyApp/safetyappSlice";
import { useGetEmployeeDropDownByProjectQuery, useGetEmployeeDropDownQuery } from "slices/employee/employeeSlice";
import { useParams } from "react-router-dom";

interface Step3ToolboxTalkProps {
  geoPermission: boolean;
  previousStep: () => void;
  nextStep: () => void;
}

const Step3ToolboxTalk: React.FC<Step3ToolboxTalkProps> = ({
  geoPermission,
  previousStep,
  nextStep,
}) => {
  const { selectedProject: urlProjectId, hwPermitNo: hwPermitNoFromURL } = useParams<{selectedProject: string; hwPermitNo: string }>();
  const { data: homeworkPermitData, refetch: refetchHomeworkPermit } =
    useGetHomeworkPermitByNumberQuery(hwPermitNoFromURL);
  // const { data: employeesData } = useGetEmployeeDropDownQuery(undefined);
  const { data: employeesData, isLoading: employeesDataLoading } = useGetEmployeeDropDownByProjectQuery(urlProjectId);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [deleteType, setDeleteType] = useState<"section" | "employee" | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
  const [workLocation, setWorkLocation] = useState<string>("");

  const [updateWorkAreaAllocation, { isLoading: isUpdating }] =
    useUpdateHomeworkWorkAreaAllocationMutation();
  const [deleteWorkAreaAllocation, { isLoading: isDeleting }] =
    useDeleteHomeworkWorkAreaAllocationMutation();

  const handleAddWorkLocation = async () => {
    const payload = {
      home_work_permit: homeworkPermitData?.id,
      work_location: workLocation,
    };

    try {
      await updateWorkAreaAllocation({
        hw_permit_no: hwPermitNoFromURL,
        updatedPermitData: payload,
      });
      refetchHomeworkPermit();
      setIsAddModalOpen(false);
      setWorkLocation("");
    } catch (error) {
      console.error("Error adding work location:", error);
    }
  };

  const handleAddEmployeeToWorkArea = async () => {
    const workLocation = homeworkPermitData?.work_area_allocation?.find(
      (area: { id: number | null }) => area.id === selectedId
    )?.work_location;

    const payload = {
      home_work_permit: homeworkPermitData?.id,
      work_location: workLocation,
      employee: selectedEmployee,
    };

    try {
      await updateWorkAreaAllocation({
        hw_permit_no: hwPermitNoFromURL,
        updatedPermitData: payload,
      });
      refetchHomeworkPermit();
      setIsEmployeeModalOpen(false);
      setSelectedEmployee(null);
    } catch (error) {
      console.error("Error adding employee:", error);
    }
  };

  const handleDelete = async () => {
    console.log({ selectedId, selectedEmployee, deleteType });
  
    if (selectedId && deleteType) {
      try {
        await deleteWorkAreaAllocation({
          sectionId: selectedId,
          employeeId: deleteType === "employee" ? selectedEmployee : null,
          type: deleteType,
        });
  
        refetchHomeworkPermit();
        setIsDeleteModalOpen(false);
        setDeleteType(null);
        setSelectedId(null);
        setSelectedEmployee(null);
      } catch (error) {
        console.error("Error deleting:", error);
      }
    }
  };
  

  return (
    <div>
      <Row>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">
            Worker and Site Area Details
          </h5>
          <Button color="primary" onClick={() => setIsAddModalOpen(true)}>
            <i className="ri-add-line align-bottom me-1"></i> Add Work Location
          </Button>
        </div>
      </Row>

      <Row className="mt-4">
        {homeworkPermitData?.work_area_allocation?.length > 0 ? (
          homeworkPermitData.work_area_allocation.map((workArea: any) => (
            <Col md={12} key={workArea.id} className="mb-4">
              <div className="d-flex align-items-center mb-4">
                <h5 className="card-title flex-grow-1 mb-0">
                  {workArea.work_location}
                </h5>
                <div className="mt-2">
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                      setSelectedId(workArea.id);
                      setIsEmployeeModalOpen(true);
                    }}
                  >
                    <i className="ri-user-add-line align-middle"></i> Add Employee
                  </Button>
                  <Button
  color="danger"
  size="sm"
  onClick={() => {
    setSelectedId(workArea?.id);
    setDeleteType("section");
    setIsDeleteModalOpen(true);
  }}
>
  <i className="ri-delete-bin-5-line align-middle"></i> Delete
</Button>
                </div>
              </div>
              <Table className="table-borderless align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Employee Name</th>
                    <th>Position</th>
                    <th>Employee Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {workArea.employee?.length > 0 ? (
                    workArea.employee.map((emp: any) => (
                      <tr key={emp.id}>
                        <td>{emp.employee_fullname}</td>
                        <td>{emp.position_name || "N/A"}</td>
                        <td>{emp.employee_number}</td>
                        <td>
                        <Button
  color="danger"
  size="sm"
  onClick={() => {
    setSelectedId(workArea.id);
    setSelectedEmployee(emp.id);
    setDeleteType("employee");
    setIsDeleteModalOpen(true);
  }}
>
<i className="ri-delete-bin-5-line align-middle"></i>
</Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No employees assigned.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          ))
        ) : (
          <Col md={12}>
            <div className="text-center">No work locations added yet.</div>
          </Col>
        )}
      </Row>

      {/* Modals */}
      {/* <AddWorkLocationModal
        isOpen={isAddModalOpen}
        toggle={() => setIsAddModalOpen(!isAddModalOpen)}
        workLocation={workLocation}
        setWorkLocation={setWorkLocation}
        isSubmitting={isUpdating}
        onSubmit={handleAddWorkLocation}
      />

      <AddEmployeeModal
        isOpen={isEmployeeModalOpen}
        toggle={() => setIsEmployeeModalOpen(!isEmployeeModalOpen)}
        employees={employeesData || []}
        employeesDataLoading={employeesDataLoading}
        selectedEmployee={selectedEmployee}
        setSelectedEmployee={setSelectedEmployee}
        isSubmitting={isUpdating}
        onSubmit={handleAddEmployeeToWorkArea}
      />

      <WorkerDeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onDelete={handleDelete}
        isDeleting={isDeleting}
      /> */}

      <div className="d-flex justify-content-between mt-3">
        <Button color="secondary" onClick={previousStep}>
          Previous
        </Button>
        <Button
          color="primary"
          disabled={!homeworkPermitData?.work_area_allocation?.length}
          onClick={nextStep}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Step3ToolboxTalk;
