import React, { useMemo, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import { useGetRolesQuery } from "slices/workflow/WorkFlowSlice";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useGetRolesQuery } from "slices/workflow/WorkFlowSlice";
import AddRoles from "./AddRoles";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

const PageAccessListView = () => {
  const [editingRoleId, setEditingRoleId] = useState<string | undefined>(
    undefined
  );
  const [isAdding, setIsAdding] = useState(false);
  const [rolesListData, setRolesListData] = useState<any>([]);

  const {
    data: roleData,
    isLoading: isLoadingRoles,
    refetch: refetchRoles,
  } = useGetRolesQuery(undefined);

  useEffect(() => {
    if (roleData) {
      setRolesListData(roleData);
    }
  }, [roleData]);

  const handleAddRole = () => {
    setEditingRoleId(undefined);
    setIsAdding(true);
  };

  const handleEditRole = (id: string) => {
    setEditingRoleId(id);
    setIsAdding(false);
  };

  const handleBackToList = () => {
    setEditingRoleId(undefined);
    setIsAdding(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "Page Access",
        accessor: "page_access_names",
        Cell: ({ row }: any) => (
          <div>
            {row.original.page_access_names.map(
              (access: any, index: number) => (
                <div key={index}>
                  <strong>{access.category}</strong>:&nbsp;&nbsp;
                  {access.action.map((action: string, actionIndex: number) => (
                    <span
                      key={actionIndex}
                      className="badge text-uppercase bg-success-subtle text-success me-1"
                    >
                      {action}
                    </span>
                  ))}
                </div>
              )
            )}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const roleId = row.original.id;
          return (
            <div>
              <ul className="list-inline hstack gap-2 mb-0">
              {IsValidPermissions(['EDIT_ROLE_AND_PAGE_ACCESS']) &&
                <li className="list-inline-item edit" title="Edit">
                  <Link
                    to="#"
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => handleEditRole(roleId)}
                  >
                    <i className="ri-pencil-fill fs-16"></i>
                  </Link>
                </li>
              }
                {/* <li className="list-inline-item view" title="View">
                  <Link
                    to={`/role-view/${roleId}`}
                    className="text-info d-inline-block view-item-btn"
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Roles | Acceess Page";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Roles List" pageTitle="Roles" />
          {(editingRoleId || isAdding) && (
            <Row className="mt-4">
              <Col lg={12}>
                <AddRoles
                  roleId={editingRoleId}
                  setShowAddComponent={handleBackToList} // Pass function to go back to list view
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Roles & Access List</h5>
                  {IsValidPermissions(['ADD_ROLE_AND_PAGE_ACCESS']) &&
                  <Button
                    color="success"
                    onClick={handleAddRole}
                    disabled={isLoadingRoles}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Add Role & Access
                  </Button>}
                </CardHeader>
                <div className="card-body pt-0">
                  <TableContainer
                    columns={columns}
                    data={rolesListData}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={10}
                    className="custom-header-css"
                    // isCustomerFilter={true}
                    SearchPlaceholder={"Search for roles..."}
                    isPagination={true}
                    isLoading={isLoadingRoles}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default PageAccessListView;
