import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  useGetGeneralworkQuestionnaireKeysQuery,
  useGetGeneralworkPermitByNumberQuery,
  useUpdateGeneralworkQuestionnaireByPermitNoMutation,
  useUpdateGeneralworkPermitMutation
} from "slices/safetyApp/safetyappSlice";

import Step2Questionnaire from "../../../CommonWizardSteps/Wizards/Step2Questionnaire";

// Define types for the form values
interface Question {
  questionnaire_key: number;
  question: string;
  answer: string; // Yes or No
  sort_id: number;
}

interface FormValues {
  questions: Question[];
  comments: string;
}

interface Step2QuestionnaireIndexProps {
  previousStep: () => void;
  nextStep: () => void;
}

const Step2QuestionnaireIndex: React.FC<Step2QuestionnaireIndexProps> = ({
  previousStep,
  nextStep,
}) => {
  // Get hwPermitNo from the URL parameters
  const { hwPermitNo } = useParams<{ hwPermitNo: string }>();

  // Fetch questionnaire data
  const { data: questionnaireData, isLoading: questionLoading } =
  useGetGeneralworkQuestionnaireKeysQuery(undefined);
  const [updatePermitQuestionnaireKey, { isLoading: patchisLoading }] =
  useUpdateGeneralworkQuestionnaireByPermitNoMutation();
  const [updatePermit] = useUpdateGeneralworkPermitMutation();

  const {
    data: permitData,
    isLoading: isPermitLoading,
    refetch: refetchPermit,
  } = useGetGeneralworkPermitByNumberQuery(hwPermitNo);

  const [initialQuestions, setInitialQuestions] = useState<Question[]>([]);

  // Initialize formik with the correct type for form values
  const formik = useFormik<FormValues>({
    initialValues: {
      questions: initialQuestions,
      comments: permitData?.questionnaire_comments || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      questions: Yup.array().of(
        Yup.object({
          answer: Yup.string().required("Answer is required"),
        })
      ),
      comments: Yup.string().required("Comments is required"),
    }),
    validateOnChange: false, // Disable validation on change
    validateOnBlur: false, // Disable validation on blur
    onSubmit: async (values) => {
      try {
        const payload = {
          questionnaire_updates: values.questions,
        };

        await updatePermitQuestionnaireKey({
          gw_permit_no: hwPermitNo,  
          updatedQuestionnaireKeyData: payload,
        });

        await updatePermit({
          gw_permit_no: hwPermitNo,
          updatedPermitData: {
            questionnaire_comments: values.comments,
          },
        }).unwrap();

        nextStep();
      } catch (error) {
        console.error("Error updating questionnaire", error);
      }
    },
  });

  return (
    <Step2Questionnaire
      previousStep={previousStep}
      formik={formik}
      permitData={permitData}
      refetchPermit={refetchPermit}
      questionnaireData={questionnaireData}
      setInitialQuestions={setInitialQuestions}
      patchisLoading={patchisLoading}
      questionLoading={questionLoading}
      isPermitLoading={isPermitLoading}
    />

  );
};

export default Step2QuestionnaireIndex;
