import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import {
  useGetWorkflowsQuery,
  useUpdateSafetyAppWorkflowProjectMutation,
  useGetProjectsByWorkFlowQuery,
} from "slices/workflow/WorkFlowSlice";

import WorkFlowAdd from "./WorkFlowAdd";

import TableContainer from "../../../Components/Common/TableContainer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import WorkflowProjectModal from "./Modal/ProjectModal";
import WorkflowChartModal from "./Modal/WorkflowChartModal";
import { IsValidPermissions } from "../../../Routes/AuthProtected";


interface Workflow {
  id: string;
  workflow_name: string;
  projects: number[]; // assuming projects are represented by an array of IDs (numbers)
  project_names: string[];
}

const SafetyAppWorkflowListView = () => {
  const {
    data: workFlow,
    isLoading: isLoadingWorkFlow,
    // refetch: refetchWorkFlow,
  } = useGetWorkflowsQuery("SAFETY_APP");

  const { data: projectData } = useGetProjectsByWorkFlowQuery('SAFETY_APP');

  const [
    updateInspectionReportProject,
    { isLoading: InspectionReportProjectLoading },
  ] = useUpdateSafetyAppWorkflowProjectMutation();

  const navigate = useNavigate();

  const [workflowData, setWorkflowData] = useState<any>([]);
  const [showAddComponent, setShowAddComponent] = useState<boolean>(false);
  const [workflowId, setWorkflowId] = useState<string | null>(null);
  const [isModalOpen, setShowModal] = useState<boolean>(false);
  const [projectID, setProjectID] = useState<any>([]);
  const [isChartModalOpen, setChartShowModal] = useState<boolean>(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(null);

  const projectOptions = useMemo(() => {
    return (
      projectData?.map((project: any) => ({
        value: project.id,
        label: project.project_name,
      })) || []
    );
  }, [projectData]);

  useEffect(() => {
    if (workFlow) {
      setWorkflowData(workFlow);
      let pj = workFlow.map((n: any) => {
        return n.projects;
      });
      setProjectID(pj.flat());
    }
  }, [workFlow]);

  const handleWorkFlowEdit = (workflow: any) => {
    setSelectedWorkflow(workflow);
    setShowModal(true);
  };

  const handleWorkFlowChartOpen = (workflow: any) => {
    setSelectedWorkflow(workflow);
    setChartShowModal(true);
    setShowAddComponent(false);
  };

  const handleSaveProjects = async (workflowId: any, selectedProjects: any) => {
    await updateInspectionReportProject({
      id: workflowId,
      projects: selectedProjects,
    });
    // refetchWorkFlow();
    setShowModal(false);
  };

 
  

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const [projectToRemove, setProjectToRemove] = useState<number | null>(null); // Track the project index to remove
  const [workflowToRemove, setWorkflowToRemove] = useState<Workflow | null>(
    null
  ); // Track the workflow being modified
  const [isRemoveModalOpen, setRemoveModalOpen] = useState<boolean>(false); // Modal open state

  const handleRemoveProjectClick = (
    workflow: Workflow,
    projectIndex: number
  ) => {
    setWorkflowToRemove(workflow); // Set the selected workflow
    setProjectToRemove(projectIndex); // Set the index of the project to be removed
    setRemoveModalOpen(true); // Open the confirmation modal
  };

  const handleConfirmRemoveProject = async () => {
    if (workflowToRemove && projectToRemove !== null) {
      // Filter out the selected project by index
      const updatedProjects = workflowToRemove?.projects.filter(
        (_, index: number) => index !== projectToRemove
      );

      // Call mutation to update workflow with the new project list
      await updateInspectionReportProject({
        id: workflowToRemove.id,
        projects: updatedProjects,
      });

      // refetchWorkFlow();
      setRemoveModalOpen(false);
    }
  };

  const deleteMultiple = () => {
    const checkall: any = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element: any) => {
      console.log("value", element.value);
      // dispatch(onDeleteContact(element.value));
      // setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  // Checked All
  // const checkedAll = useCallback(() => {
  //   const checkall: any = document.getElementById("checkBoxAll");
  //   const ele = document.querySelectorAll(".contactCheckBox");

  //   if (checkall.checked) {
  //     ele.forEach((ele: any) => {
  //       ele.checked = true;
  //     });
  //   } else {
  //     ele.forEach((ele: any) => {
  //       ele.checked = false;
  //     });
  //   }
  //   deleteCheckbox();
  // }, []);

  const deleteCheckbox = () => {
    const ele: any = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Workflow Name",
        accessor: "workflow_name",
        filterable: false,
      },
      {
        Header: "Approval Steps",
        accessor: "approvals",
        filterable: false,
        Cell: ({ row }: any) => (
          <span className="badge bg-primary">
            {row?.original?.approvals?.length}
          </span>
        ),
      },
      {
        Header: "Project Names",
        accessor: "project_names",
        filterable: false,
        Cell: ({ row }: any) => (
          <>
            {row?.original?.project_names?.length > 0 ? (
              row?.original?.project_names?.map(
                (projectName: string, index: number) => (
                  <span key={projectName} className="badge bg-secondary me-1">
                    {projectName}
                    {/* {" "}
                    <i
                      className="ri-close-line text-white ms-1 cursor-pointer"
                      onClick={() =>
                        handleRemoveProjectClick(row?.original, index)
                      }
                    ></i> */}
                  </span>
                )
              )
            ) : (
              <span className="text-muted">No Projects Assigned</span>
            )}
          </>
        ),
      },

      {
        Header: "Workflow",
        accessor: "approvals_org",
        filterable: false,
        Cell: ({ row }: any) =>
          row.original.approvals.length > 0 ? (
            <Link
              to={"#"}
              onClick={() => handleWorkFlowChartOpen(row?.original)}
            >
              <i className="ri-share-circle-fill fs-16"></i>
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const handleWorkFlowEditById = () => {
            setWorkflowId(row?.original?.id);
            setShowAddComponent(true);
          };
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item edit" title="Assign Projects">
                {row.original.approvals.length > 0 ? (
                  <div
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => handleWorkFlowEdit(row?.original)}
                  >
                    <i className="ri-contacts-fill fs-16"></i>{" "}
                  </div>
                ) : (
                  ""
                )}
              </li> */}
              {IsValidPermissions(['EDIT_SAFETY_APP_WORKFLOW']) &&
              <li className="list-inline-item edit" title="View">
                <div
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={handleWorkFlowEditById}
                >
                  <i className="ri-edit-fill fs-16"></i>
                </div>
              </li>
              }
            </ul>
          );
        },
      },
    ],
    [navigate]
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState<boolean>(false);

  document.title = "Safety App WorkFlow List";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={workFlow}
          columns={columns}
          name={"WorkFlow"}
        />
        <Container fluid>
          <BreadCrumb title="WorkFlow List" pageTitle="Safety App" />
          <Row>
            <Col lg={12}>
              {showAddComponent && (
                <WorkFlowAdd
                  setShowAddComponent={setShowAddComponent}
                  workflowId={workflowId}
                />
              )}
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">WorkFLow List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                      {IsValidPermissions(['ADD_SAFETY_APP_WORKFLOW']) &&
                        <>
                        <button
                          type="button"
                          className="btn btn-success add-btn me-1"
                          id="create-btn"
                          onClick={() => {
                            setWorkflowId(null);
                            setShowAddComponent(true);
                          }}
                          disabled={isLoadingWorkFlow}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Workflow
                        </button>{" "}
                        </>
                      }
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setIsExportCSV(true)}
                          disabled={isLoadingWorkFlow || workFlow?.length === 0}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={workflowData}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // isCustomerFilter={true}
                      SearchPlaceholder={"Search for members..."}
                      isPagination={true}
                      isLoading={isLoadingWorkFlow}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          {selectedWorkflow && (
            <WorkflowProjectModal
              isOpen={isModalOpen}
              toggle={() => setShowModal(false)}
              workflowName={selectedWorkflow?.workflow_name}
              workflowId={selectedWorkflow?.id}
              projects={projectOptions}
              projectID={projectID}
              selectedProjects={selectedWorkflow?.projects || []}
              onSave={handleSaveProjects}
              InspectionReportProjectLoading={InspectionReportProjectLoading}
            />
          )}

          <WorkflowChartModal
            isOpen={isChartModalOpen}
            toggle={() => setChartShowModal(false)}
            workflowName={selectedWorkflow?.workflow_name}
            workflowId={selectedWorkflow?.id}
            projects={projectOptions}
            approvals={selectedWorkflow?.approvals}
            selectedProjects={selectedWorkflow?.projects || []}
            onSave={handleSaveProjects}
          />

          <Modal
            isOpen={isRemoveModalOpen}
            toggle={() => setRemoveModalOpen(false)}
          >
            <ModalHeader toggle={() => setRemoveModalOpen(false)}>
              Confirm Removal
            </ModalHeader>
            <ModalBody>
              Are you sure you want to remove this project from the workflow?
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-danger"
                onClick={handleConfirmRemoveProject}
                disabled={InspectionReportProjectLoading}
              >
                {InspectionReportProjectLoading ? (
                  <>
                    <Spinner size="sm" />
                    &nbsp;Loading....
                  </>
                ) : (
                  "Remove"
                )}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setRemoveModalOpen(false)}
                disabled={InspectionReportProjectLoading}
              >
                Cancel
              </button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default SafetyAppWorkflowListView;
