import React, { useState, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import classnames from "classnames";

//import images
import OverviewTab from "./OverviewTab";
import ActivitiesTab from "Components/Common/ActivitesTab";
import TaskDocumentList from "pages/Tasks/TaskDocuments";
import { useGetAuditLogsQuery } from "slices/employee/employeeSlice";
import { getTextColor } from "../../../../helpers/common_helper";
import { Tooltip } from 'react-tooltip';
import { IsValidPermissions } from "Routes/AuthProtected";

interface TaskProps {
  getTaskById: any;
  setTask: any;
  toggle: any;
  toggleChangeStatus: any;
}

const Summary: React.FC<TaskProps> = ({ getTaskById, setTask, toggle, toggleChangeStatus }) => {
  const { data: auditLogs, isLoading: auditLogsLoading } = useGetAuditLogsQuery(
    { model_name: "task", object_id: getTaskById?.id }
  );
  // Update Data
  const handleCustomerClick = useCallback(
    (arg: any) => {
      const task = arg;
      setTask({
        id: task.id,
        title: task.title,
        description: task.description,
        projects: task.projects,
        priority: task.priority,
        assignee: task.assignee,
        reporter: task.reporter,
        task_status: task.task_status,
        start_date: task.start_date,
        due_date: task.due_date,
      });
      toggle();
    },
    [toggle]
  );

  // Update Status
  const handleCustomerStatusClick = useCallback(
    (arg: any) => {
      const task = arg;
      setTask({
        id: task.id,
        title: task.title,
        description: task.description,
        projects: task.projects,
        priority: task.priority,
        assignee: task.assignee,
        reporter: task.reporter,
        task_status: task.task_status,
        start_date: task.start_date,
        due_date: task.due_date,
      });
      toggleChangeStatus();
    },
    [toggleChangeStatus]
  );

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const Status = (props: any) => {
    return (
      <React.Fragment>
        <span className="badge text-uppercase" style={{
          "backgroundColor": props.color,
          "color": getTextColor(props.color)
        }}>
          {props.value}
        </span>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".edit-task-class" place='bottom' content="Update Task" />
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".upadte-task-status-class" place='bottom' content="Update Task Status" />
      <Tooltip style={{ zIndex: 10 }} anchorSelect=".export-task-status-class" place='bottom' content="Download" />

      <div className="page-content pt-3" >
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4">
                <div>
                  <CardBody className="pb-0 px-4">
                    <Row className="mb-3">
                      <div className="col-md">
                        <Row className="align-items-center g-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h4 className="fw-bold">Task Detail View</h4>
                            </div>

                            <div>
                            
                              <button
                                type="button"
                                className="btn py-0 fs-16 text-body shadow-none me-3 export-task-status-class"
                              >
                                <i className="ri-download-2-fill"></i>
                              </button>
                            
                            {(IsValidPermissions(['EDIT_TASK'])) &&
                              <>
                              <button
                                className="btn btn-sm btn-primary add-btn me-1 edit-task-class"
                                // disabled={isTaskLoading}
                                onClick={() => {
                                  handleCustomerClick(getTaskById);
                                }}
                              >
                                <i className="ri-pencil-fill align-bottom me-1"></i> Update Task
                              </button>

                              <button
                                className="btn btn-sm btn-secondary add-btn me-1 upadte-task-status-class"
                                // disabled={isTaskLoading}
                                onClick={() => {
                                  handleCustomerStatusClick(getTaskById);
                                }}
                              >
                                <i className="ri-swap-box-line align-bottom me-1"></i>
                                Change Status
                              </button>
                              </>}
                            </div>
                          </div>
                          <hr />
                          {/* <div className="col-md-auto">
                            <div className="avatar-md">
                              <div className="mt-4">
                                <h4>{getTaskById?.task_id}</h4>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md">
                            <div className="d-flex justify-content-between">
                              <div><h4 className="fw-bold">Title : <span className="text-muted">{getTaskById?.title} ({getTaskById?.task_id})</span></h4></div>
                              <div className="hstack gap-3 flex-wrap">
                                <div>
                                  Project Name :{" "}
                                  {getTaskById?.get_employee_projects}
                                </div>
                                <div className="vr"></div>
                                <div>
                                  Start Date :{" "}
                                  <span className="fw-medium">
                                    {getTaskById?.start_date}
                                  </span>
                                </div>
                                <div className="vr"></div>
                                <div>
                                  Due Date :{" "}
                                  <span className="fw-medium">
                                    {getTaskById?.due_date}
                                  </span>
                                </div>
                                <div className="vr"></div>
                                <Status value={getTaskById?.task_status_name} color={getTaskById?.task_status_color} />
                                {/* <div className="badge rounded-pill bg-danger fs-12">
                                  {projectData?.status_name}
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                      {/* <div className="col-md-auto">
                        <div className="hstack gap-1 flex-wrap">
                          <button
                            type="button"
                            className="btn py-0 fs-16 favourite-btn active shadow-none"
                          >
                            <i className="ri-star-fill"></i>
                          </button>
                          <button
                            type="button"
                            className="btn py-0 fs-16 text-body shadow-none"
                          >
                            <i className="ri-share-line"></i>
                          </button>
                          <button
                            type="button"
                            className="btn py-0 fs-16 text-body shadow-none"
                          >
                            <i className="ri-flag-line"></i>
                          </button>
                        </div>
                      </div> */}
                    </Row>

                    <Nav
                      className="nav-tabs-custom border-bottom-0"
                      role="tablist"
                      style={{ background: "lavender" }}
                    >
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "1" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("1");
                          }}
                          href="#"
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "2" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("2");
                          }}
                          href="#"
                        >
                          Documents
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "3" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("3");
                          }}
                          href="#"
                        >
                          Activities
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1">
                  <OverviewTab projectData={getTaskById} />
                </TabPane>
                <TabPane tabId="2">
                  <TaskDocumentList task_id={getTaskById?.id} />
                </TabPane>
                <TabPane tabId="3">
                  <ActivitiesTab
                    activities={auditLogs}
                    isLoading={auditLogsLoading}
                  />
                </TabPane>
                {/* <TabPane tabId="4">
                  <TeamTab />
                </TabPane> */}
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Summary;
