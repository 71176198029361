import React, { createContext, useState, ReactNode } from "react";

// Create the context interface
interface ServiceContextProps {
  selectedRoleId: string | null;
  selectedRoleName: string | null;
  getPermissionList: string[]; // permissions as a string array
  appSettings: Record<string, string>; // key-value pairs for app settings
  featureFlags: Record<string, boolean>; // key-value pairs for feature flags
  isAuthenticated: boolean;
  getEmployeeUUID: string;
  getEmployeeID: string;
  isAdminUser: boolean | null;
  setselectedRoleId: (id: string) => void;
  setSelectedRoleName: (name: string) => void;
  setPermissionsList: (permissions: string[]) => void;
  setAppSettings: (settings: Record<string, string>) => void;
  setFeatureFlags: (flags: Record<string, boolean>) => void;
  setIsAuthenticated: (authStatus: boolean) => void;
  setEmployeeUUID: (uuid: string) => void;
  setEmployeeID: (uuid: string) => void;
  setIsAdminUser: (isAdmin: boolean | null) => void;
}

// Initial context value
const initialContextValue: ServiceContextProps = {
  selectedRoleId: null,
  selectedRoleName: null,
  getPermissionList: [],
  appSettings: {},
  featureFlags: {},
  isAuthenticated: false,
  getEmployeeUUID: "",
  getEmployeeID: "",
  isAdminUser: null,
  setselectedRoleId: () => {},
  setSelectedRoleName: () => {},
  setPermissionsList: () => {},
  setAppSettings: () => {},
  setFeatureFlags: () => {},
  setIsAuthenticated: () => {},
  setEmployeeUUID: () => {},
  setEmployeeID: () => {},
  setIsAdminUser: () => {},
};

// Create the context
export const ServiceContext = createContext<ServiceContextProps>(initialContextValue);

// Context Provider component
export const ServiceContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedRoleId, setselectedRoleId] = useState<string | null>(null);
  const [selectedRoleName, setSelectedRoleName] = useState<string | null>(null);
  const [getPermissionList, setPermissionsList] = useState<string[]>([]);
  const [appSettings, setAppSettings] = useState<Record<string, string>>({});
  const [featureFlags, setFeatureFlags] = useState<Record<string, boolean>>({});
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [getEmployeeUUID, setEmployeeUUID] = useState<string>("");
  const [getEmployeeID, setEmployeeID] = useState<string>("");
  const [isAdminUser, setIsAdminUser] = useState<boolean | null>(null);

  return (
    <ServiceContext.Provider
      value={{
        selectedRoleId,
        selectedRoleName,
        getPermissionList,
        appSettings,
        featureFlags,
        isAuthenticated,
        getEmployeeUUID,
        getEmployeeID,
        isAdminUser,
        setselectedRoleId,
        setSelectedRoleName,
        setPermissionsList,
        setAppSettings,
        setFeatureFlags,
        setIsAuthenticated,
        setEmployeeUUID,
        setEmployeeID,
        setIsAdminUser,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
