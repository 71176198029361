import React, { useState, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Button,
  Badge
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import {
  useGetExcavationPermitViewQuery,
  useGenerateHomeworkPermitPDFMutation,
} from "slices/safetyApp/safetyappSlice";
import { useGetAllProjectsQuery } from "slices/projects/projectSlice";
import TableContainer from "Components/Common/TableContainer"; 
import Loader from "Components/Common/Loader";
import { Tooltip } from "react-tooltip";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS } from "helpers/common_helper";
import { formatDateTime } from "helpers/date_utils";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

const ExcavationWorkPermitListView = () => {
  const { data: excavationWorkPermits , isLoading } =
  useGetExcavationPermitViewQuery(undefined);
  const { data: projectData } = useGetAllProjectsQuery(undefined);
  const [generatePDF] = useGenerateHomeworkPermitPDFMutation();

  const navigate = useNavigate();

  // State for Modal and Selected Project
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  // Toggle Modal
  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  // Redirect to Home Work Permit Wizard with Project ID in the URL
  const handleCreateNewReport = () => {
    if (selectedProject) {
      navigate(`/excavation-permit-wizard/${selectedProject}`);
    }
  };

  // Map Projects to Dropdown Options
  const projectOptions = useMemo(() => {
    return (
      projectData?.map((project: any) => ({
        value: project.id,
        label: project.project_name,
      })) || []
    );
  }, [projectData]);

  // Define Columns for Table
  const columns = useMemo(
    () => [
      {
        Header: "Permit No",
        accessor: "ew_permit_no", 
        Cell: ({
          row: {
            original: { ew_permit_no  },
          },
        }: any) => (
          <Link
            to={`/excavation-permit-details/${ew_permit_no }/`}
            className="text-primary d-inline-block edit-item-btn view-task-status-class"
          >
            {ew_permit_no }
          </Link>
        ),
      },
      {
        Header: "Work Supervised By",
        accessor: "work_supervised_by",
        Cell: (cell: { value: any }) => (
          <>
            {cell.value ?
              <Badge color="primary">{cell.value}</Badge>
              : null}
          </>
        ),
      },
      // {
      //   Header: "Work Location",
      //   accessor: "work_location",
      // },
      {
        Header: "Project",
        accessor: "project_name", 
      },
      // {
      //   Header: "Company",
      //   accessor: "company_name", 
      // },
      {
        Header: "Start & End Date of Work",
        accessor: "start_date_of_work", // Start Date
        Cell: ({
          row: {
            original: { start_date_of_work, start_time_of_work, end_date_of_work, end_time_of_work },
          },
        }: any) => (<>{formatDateTime(start_date_of_work, 'date')} {formatDateTime(start_time_of_work, 'time')} <i className="las la-arrows-alt-h"></i> {formatDateTime(end_date_of_work, 'date')} {formatDateTime(end_time_of_work, 'time')}</>),
      },
      // {
      //   Header: "Start Date of Work",
      //   accessor: "start_date_of_work", // Start Date
      //   Cell: ({ value }: any) => new Date(value).toLocaleDateString(),
      // },

      // {
      //   Header: "End Date of Work",
      //   accessor: "end_date_of_work", 
      //   Cell: ({ value }: any) => new Date(value).toLocaleDateString(), 
      // },
      {
        Header: "Submission Status",
        accessor: "report_status_name", 
        Cell: (cell: { value: any }) => (
          <>
            {cell.value ?
              <BadgeStatus value={cell.value} color={STATUS_COLORS[cell.value]} />
              : null}
          </>
        ),
      },
      {
        Header: "Approval Status",
        accessor: "approval_status_name", 
        Cell: (cell: { value: any }) => (
          <>
            {cell.value ?
              <BadgeStatus value={cell.value} color={STATUS_COLORS[cell.value]} />
              : null}
          </>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({
          row: {
            original: { ew_permit_no, project, report_status_name, approval_status_name },
          },
        }: any) => (
          <div className="d-flex gap-2">
            <ul className="list-inline hstack gap-2 mb-0">
            {(IsValidPermissions(['VIEW_ALL_EXCAVATION_PERMIT']) || IsValidPermissions(['VIEW_ONLY_EXCAVATION_PERMIT'])) &&
              <li className="list-inline-item edit" title="View">
                <Link
                  to={`/excavation-permit-details/${ew_permit_no }/`}
                  className="text-primary d-inline-block edit-item-btn view-task-status-class"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            }

              {IsValidPermissions(['EDIT_EXCAVATION_PERMIT']) && (report_status_name === 'Draft' || report_status_name === 'Resubmission') ?
                <li className="list-inline-item edit" title="Edit">
                  <Link
                    to={`/excavation-permit-wizard/${project}/${ew_permit_no }`}
                    className="text-primary d-inline-block edit-item-btn edit-task-status-class"
                  >
                    <i className="ri-pencil-fill fs-16"></i>
                  </Link>
                </li> : <><i className="ri-pencil-fill fs-16 edit-task-status-class" title="Edit" style={{ color: "gray" }}></i></>}
            
                
                {IsValidPermissions(['DOWNLOAD_EXCAVATION_PERMIT']) && (report_status_name === 'Closed' || report_status_name === 'Approved') ?
                <li className="list-inline-item edit" title="Export Permit">
                  <Link
                    to={`#`}
                    className="text-primary d-inline-block edit-item-btn export-task-status-class"
                    onClick={() => generatePDF(ew_permit_no )}
                  >
                    <i className="ri-download-fill"></i>
                  </Link></li> : <></>
                }
              
            </ul>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".export-task-status-class"
        place="bottom"
        content="Export Permit"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".view-task-status-class"
        place="bottom"
        content="View"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".edit-task-status-class"
        place="bottom"
        content="Edit"
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Excavation Permit List" pageTitle="Safety App" />
          <Row>
            <Col lg={12}>
              <Card id="workatHeightPermitList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm>
                      <div>
                        <h5 className="card-title mb-0">
                        Excavation Permit List
                        </h5>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div>
                      {(IsValidPermissions(['ADD_EXCAVATION_PERMIT'])) &&
                        <Button color="success" onClick={toggleModal}>
                          <i className="ri-add-line align-bottom me-1"></i>
                          Excavation Permit
                        </Button>
                      }
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={excavationWorkPermits?.results || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      className="custom-header-css"
                      isPagination={true}
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Modal for Creating New Hot Work Permit */}
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            Create New Excavation Permit
          </ModalHeader>
          <ModalBody>
            <Label for="projectSelect">Project</Label>
            <Input
              type="select"
              id="projectSelect"
              onChange={(e) => setSelectedProject(e.target.value)}
              value={selectedProject || ""}
            >
              <option value="">Select Project</option>
              {projectOptions.map((project) => (
                <option key={project.value} value={project.value}>
                  {project.label}
                </option>
              ))}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={handleCreateNewReport}
              disabled={!selectedProject}
            >
              Create New Report
            </Button>
            <Button color="danger" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ExcavationWorkPermitListView;
