import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Label,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

// Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

// Redux
import { useSelector } from "react-redux";
import {
  useGetEmployeeDropDownQuery,
  usePatchEmployeeRoleMutation,
} from "slices/employee/employeeSlice";
import { useGetRolesQuery } from "slices/workflow/WorkFlowSlice";
const RoleAccessList = () => {
  const {
    data: employeeData,
    isLoading: isEmployeeLoading,
    refetch: refetchEmployee,
  } = useGetEmployeeDropDownQuery(undefined);

  const { data: rolesData } = useGetRolesQuery(undefined);
  const [patchEmployeeRole, { isLoading: isLoadingEmployee }] =
    usePatchEmployeeRoleMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [rolesListData, setRolesListData] = useState<any>([]);

  useEffect(() => {
    if (employeeData) {
      setRolesListData(employeeData);
    }
  }, [employeeData]);

  const navigate = useNavigate();

  const roleOptions = useMemo(() => {
    return (
      rolesData?.map((role: any) => ({
        value: role.id,
        label: role.name,
      })) || []
    );
  }, [rolesData]);

  const handleEditClick = (employee: any) => {
    setSelectedEmployee(employee);
    setSelectedRoles(
      employee.role.map((roleId: any, index: number) => ({
        value: roleId,
        label: employee.role_name[index],
      }))
    );
    setIsModalOpen(true);
  };

  const handleSaveRoles = () => {
    if (selectedEmployee && selectedRoles.length > 0) {
      const updateData = {
        role: selectedRoles.map((role: any) => role.value),
      };

      patchEmployeeRole({
        employee_uuid: selectedEmployee.employee_uuid,
        updateData,
      })
        .then(() => {
          refetchEmployee();
          toast.success("Roles updated successfully!");
          setIsModalOpen(false);
        })
        .catch(() => {
          toast.error("Failed to update roles.");
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "employee_fullname",
        filterable: false,
      },
      {
        Header: "Position Name",
        accessor: "position_name",
        filterable: false,
      },
      {
        Header: "Role",
        accessor: "role_name",
        filterable: false,
        Cell: ({ row }: any) => (
          <>
            {row.original.role_name?.map((role: any, index: number) => (
              <span key={index} className="badge bg-secondary me-1">
                {role}
              </span>
            ))}
          </>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            {IsValidPermissions(['EDIT_ROLE_ACCESS']) &&
            <li className="list-inline-item edit" title="Edit">
              <div
                className="text-primary d-inline-block edit-item-btn"
                onClick={() => handleEditClick(row.original)}
              >
                <i className="ri-edit-fill fs-16"></i>
              </div>
            </li>
            }
          </ul>
        ),
      },
    ],
    []
  );

  document.title =
    "Role Access List";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Role Access List" pageTitle="Employee" />
          <Row>
            <Col lg={12}>
              <Card id="employeeList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Role Access List</h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    {isEmployeeLoading ? (
                      <Loader />
                    ) : (
                      <TableContainer
                        columns={columns}
                        data={rolesListData}
                        isGlobalFilter={true}
                        customPageSize={10}
                        className="custom-header-css"
                        isPagination={true}
                      />
                    )}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          {selectedEmployee && (
            <Modal
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
            >
              <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
                Edit Roles {selectedEmployee.employee_fullname}
              </ModalHeader>
              <ModalBody>
                <Form>
                  <Label>Roles</Label>
                  <Select
                    isMulti
                    options={roleOptions}
                    value={selectedRoles}
                    onChange={setSelectedRoles}
                  />
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={handleSaveRoles}>
                  {isLoadingEmployee ? (
                    <span className="d-flex align-items-center">
                      <Spinner size="sm" className="flex-shrink-0" />
                      <span className="flex-grow-1 ms-2">Loading...</span>
                    </span>
                  ) : (
                    <>Save</>
                  )}
                </Button>
                <Button
                  color="secondary"
                  disabled={isLoadingEmployee}
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RoleAccessList;
