import React, { useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { ServiceContext } from "context/ServiceContextProvider";
import { useDispatch } from "react-redux";
import { useAuthServices } from "AuthServices/AuthServices";
import { useProfile } from "../Components/Hooks/UserHooks";
import { logoutUser } from "../slices/auth/login/thunk";
import { useGetUserPermissionsByRoleIdQuery } from "slices/employee/employeeSlice";

const AuthProtected = (props: any) => {
  const dispatch: any = useDispatch();
  let location = useLocation();
  const { getSelectedRole } = useAuthServices();
  const { userProfile, loading, token } = useProfile();

  const context = useContext(ServiceContext);
  if (!context) {
    throw new Error(
      "ServiceContext must be used within a ServiceContextProvider"
    );
  }

  const {
    selectedRoleId,
    setPermissionsList,
    setAppSettings,
    setFeatureFlags,
    setEmployeeUUID,
    setEmployeeID,
    getPermissionList,
    appSettings,
    featureFlags,
  } = context;

  const {
    data: permissionsData,
    error: permissionsError,
    isLoading: isPermissionsLoading,
    refetch: refetchPermissions,
  } = useGetUserPermissionsByRoleIdQuery(selectedRoleId, {
    skip: !selectedRoleId,
  });

  useEffect(() => {
    // Fetch selected role from session storage and update the context and Redux store
    const selectedRole = getSelectedRole();
  }, [getSelectedRole]);

  // Set Authorization token if available
  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && !loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  // Refetch permissions when role changes
  useEffect(() => {
    console.log('selectedRoleId', location.pathname);
    if (selectedRoleId) {
      refetchPermissions();
    }
  }, [selectedRoleId, refetchPermissions, location.pathname]);

  // Set fetched permissions, app settings, and feature flags in context
  useEffect(() => {
    if (permissionsData) {
      const { user_permissions, app_settings, feature_flag } = permissionsData;

      // Set Employee UUID
      setEmployeeUUID(getUUID() || '');

      // Set Employee ID
      setEmployeeID(getEmpID() || '');

      // Set permissions in the context
      setPermissionsList(user_permissions);

      // Convert app settings into key-value pairs
      const settingsObject = app_settings?.reduce((acc: any, setting: any) => {
        const [key, value] = Object?.entries(setting)[0];
        acc[key] = value;
        return acc;
      }, {});
      setAppSettings(settingsObject);

      // Convert feature flags into key-value pairs
      const flagsObject = feature_flag?.reduce((acc: any, flag: any) => {
        const [key, value] = Object?.entries(flag)[0];
        acc[key] = value;
        return acc;
      }, {});
      setFeatureFlags(flagsObject);
    }
  }, [permissionsData, setPermissionsList, setAppSettings, setFeatureFlags]);

  // Handle API errors (optional)
  useEffect(() => {
    if (permissionsError) {
      console.error("Failed to fetch permissions: ", permissionsError);
    }
  }, [permissionsError]);

  if (!isPermissionsLoading && permissionsError){
    return <Navigate to="/login" />;
  }

  if (!loading && !userProfile && !token) {
    return <Navigate to="/login" />;
  }

  return <>{props.children}</>;
};

const getUUID = () => {
  const authUser = localStorage.getItem("authUser");
  return authUser ? JSON.parse(authUser).employee_uuid : null;
};

const getEmpID = () => {
  const authUser = localStorage.getItem("authUser");
  return authUser ? JSON.parse(authUser).user_id : null;
};

// IsValidPermissions function
export function IsValidPermissions(perms: string[]): boolean {
  const { getPermissionList } = useContext(ServiceContext);

  // Check if all required permissions exist in the user's permission list
  return perms?.every((perm) => getPermissionList?.includes(perm));
  // return true;
}

// IsValidPermissions function
export function IsValidRoutePermissions(perms: string[]): boolean {
  const { getPermissionList } = useContext(ServiceContext);

  if (perms.length === 0) {
    return false;
  }

  // Check if all required permissions exist in the user's permission list
  return perms?.some((perm) => getPermissionList?.includes(perm));
}

// IsValidPermissions by UUID function
export function IsValidPermissionsWithUUID(perms: string[], employeeUUID: string): boolean {
  const { getPermissionList, getEmployeeUUID } = useContext(ServiceContext);

  // Check if all required permissions exist in the user's permission list
  if (perms?.every((perm) => getPermissionList?.includes(perm)) && getEmployeeUUID === employeeUUID){
    return true;
  }
  return false;
}

export default AuthProtected;
