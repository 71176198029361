import React from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap";
import FileUploadModal from "../SteperModals/FileUploadModal";
import DeleteConfirmationModal from "../SteperModals/DeleteConfirmationModal";

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
  pdf: "ri-file-pdf-line",
  doc: "ri-file-word-line",
  docx: "ri-file-word-line",
  zip: "ri-folder-zip-line",
  rar: "ri-folder-zip-line",
  default: "ri-file-line",
};

interface Step5FileUploadProps {
  previousStep: () => void;
  nextStep: () => void;
  permitData: any;
  handleFileUpload: any;
  handleDeleteDocument: any;
  handleAcceptedFiles: any;
  setIsUploadModalOpen: any
  setDeleteDocumentId: any;
  setDocumentToDeleteName: any;
  setIsDeleteModalOpen: any;
  documentToDeleteName: any;
  isPermitLoading: boolean;
  isUploadModalOpen: boolean;
  isUploading: boolean;
  isDeleteModalOpen: boolean;
  isDeleting: boolean;
  isPermitFetching: boolean;
  selectedFiles: any;
}

const Step4FileUpload: React.FC<Step5FileUploadProps> = ({
  previousStep,
  nextStep,
  permitData,
  handleFileUpload,
  handleDeleteDocument,
  handleAcceptedFiles,
  setDeleteDocumentId,
  setIsUploadModalOpen,
  setDocumentToDeleteName,
  setIsDeleteModalOpen,
  documentToDeleteName,
  isPermitLoading,
  isUploadModalOpen,
  isDeleteModalOpen,
  isUploading,
  isDeleting,
  isPermitFetching,
  selectedFiles
}) => {

  const handleDeleteClick = (documentId: string, documentName: string) => {
    setDeleteDocumentId(documentId);
    setDocumentToDeleteName(documentName);
    setIsDeleteModalOpen(true);
  };

  const getFileTypeIconOrImage = (documentUrl: string) => {
    const fileName = documentUrl.split("/").pop()?.split("?")[0] || "";
    const fileExtension = fileName.split(".").pop()?.toLowerCase() || "default";

    if (imageExtensions.includes(fileExtension)) {
      return (
        <CardImg
          top
          width="100%"
          height="150px"
          src={documentUrl}
          alt={fileName}
          style={{
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      );
    } else {
      const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px", fontSize: "2rem" }}
        >
          <i className={`${iconClass}`} />
        </div>
      );
    }
  };

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length > maxLength) {
      return fileName.substring(0, maxLength) + "...";
    }
    return fileName;
  };

  const extractDocumentName = (url: string) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  return (
    <div>
      {/* Upload Button */}
      <Row>
      <div className="d-flex" style={{justifyContent: "space-between"}}>
        <h5>File Upload Details</h5>
        <Button color="primary" className="d-flex mb-1" onClick={() => setIsUploadModalOpen(true)}>
            {isUploading ? <Spinner size="sm" /> : "Upload Files"}
          </Button>
      </div>
        <hr />

        {/* <div className="d-flex align-items-center mb-4">
          <Button color="primary" onClick={() => setIsUploadModalOpen(true)}>
            {isUploading ? <Spinner size="sm" /> : "Upload Files"}
          </Button>
        </div> */}
      </Row>

      {/* Preview Section */}
      <Row className="mt-4">
        {isPermitLoading ? (
          <Spinner color="primary" />
        ) : permitData?.documents?.length > 0 ? (
          permitData.documents.map((document: any, index: number) => (
            <Col key={index} md={3} className="mb-4">
              <Card>
                {getFileTypeIconOrImage(document.attachment_url)}

                <CardBody className="text-center">
                  <CardTitle tag="h6" className="mb-3">
                    {truncateFileName(extractDocumentName(document.attachment))}
                  </CardTitle>

                  <Button
                    color="danger"
                    size="sm"
                    onClick={() =>
                      handleDeleteClick(
                        document.id,
                        extractDocumentName(document.attachment)
                      )
                    }
                    disabled={isDeleting}
                  >
                    {isDeleting ? <Spinner size="sm" /> : <><i className="ri-delete-bin-5-line align-middle "></i></>}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <Col md={12}>
            <div className="d-flex align-items-center justify-content-center">
              <h4>No files available for preview</h4>
            </div>
          </Col>
        )}
      </Row>

      {/* Navigation Buttons */}
      <div className="d-flex justify-content-between mt-4">
        <Button color="secondary" onClick={previousStep}>
          Previous
        </Button>
        <Button
          color="primary"
          onClick={nextStep}
          disabled={isUploading || isPermitLoading}
        >
          Next
        </Button>
      </div>

      {/* Modals */}
      <FileUploadModal
        isOpen={isUploadModalOpen}
        toggle={() => setIsUploadModalOpen(!isUploadModalOpen)}
        onFileUpload={handleFileUpload}
        onFileDrop={handleAcceptedFiles}
        selectedFiles={selectedFiles}
        isUploading={isUploading}
        isPermitFetching={isPermitFetching}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onDelete={handleDeleteDocument}
        documentName={documentToDeleteName}
        isDeleting={isDeleting}
        isPermitFetching={isPermitFetching}
      />
    </div>
  );
};

export default Step4FileUpload;
