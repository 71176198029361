export const formatDateTime = (value: string, type: string) => {
    if (!value) return null;

    if (type === 'date') {
        const options: any = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(value);
        return date.toLocaleDateString('en-GB', options);
    }

    if (type === 'time') {
        const [hours, minutes] = value.split(':');
        return `${hours}:${minutes}`;
    }

    if (type === 'datetime') {
        const date = new Date(value);
        const dateOptions: any = { day: '2-digit', month: 'short', year: 'numeric' };
        const timeOptions: any = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC' };
        
        const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
    
        return `${formattedDate} ${formattedTime}`;
      }

    return value;
}